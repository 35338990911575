import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { ActivatedRoute, Router, UrlTree } from '@angular/router';
import { L10nTranslationService } from 'angular-l10n';
import { Subscription } from 'rxjs';
import { CustomL10nIntlService } from 'src/data/custom-locale.service';
import { Co2Range, MenuPublishAvailableMenu, MenuPublishDate, MenuPublishDateDataReport, MenuPublishMealOption, MenuPublishMenu } from 'src/data/interfaces';
import { TK } from 'src/data/translation-keys';
import { getDateAsString, getDateOnAnotherWeek, getDatesBetween, getFridayOfDate, getMondayOfDate, getSundayOfDate, isDateGreater, isDateLessOrSame } from 'src/date-functions';
import { SingleLanguage } from '../menu-language-bar/menu-language-bar.component';
import { MenuWeekHeaderDates } from '../menu-week-header/menu-week-header.component';
import { AppConfigService } from '../services/app-config-service';
import { AppThemeService } from '../services/app-theme.service';
import { GeneralServiceError } from '../services/general-service-error';
import { MenuService } from '../services/menu.service';
import { MenuViewService } from './menu-view.service';
import { UrlDecoder } from '../services/url-decoder';

@Component({
  selector: 'app-menu-view',
  templateUrl: './menu-view.component.html',
  styleUrls: ['./menu-view.component.scss']
})
export class MenuViewComponent implements OnInit, OnDestroy {
  @ViewChild(MatAccordion, { static: true }) accordion: MatAccordion;

  private subscriptions: Subscription[] = [];
  public tenantName!: string;
  public siteName!: string;
  public menuName!: string;
  public date!: string;
  public language!: string;
  public weekend!: boolean;
  public theme!: string;
  public menuWeekHeaderDates!: MenuWeekHeaderDates;
  public menuPublishMenu!: MenuPublishMenu;
  public isAllOpened!: boolean;
  public currentLanguages: SingleLanguage[];

  public currentWeekMonday!: string;
  public currentWeekFriday!: string;
  public currentWeekSunday!: string;

  public isPreviousWeekAvailable: boolean = false;
  public isNextWeekAvailable: boolean = false;
  public isDateOutOfData: boolean = false;

  public showMainError = false;
  public showTenantOrSiteNotFound = false;
  public showMenuNotFound = false;

  public menuDays: MenuDay[];
  public availableMenus: MenuPublishAvailableMenu[];

  public legendRanges: Co2Range[];

  public service: MenuViewService;

  private allowedLanguageCodesInOrder: string[] = ['fi', 'sv', 'en'];
  private urlDecoder: UrlDecoder = new UrlDecoder();

  tk = {
    error_fetching_data: '',
    menu_not_found: '',
    site_not_found: ''
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private route: ActivatedRoute,
    private router: Router,
    private menuService: MenuService,
    public appThemeService: AppThemeService,
    public ts: L10nTranslationService,
    public customLocaleService: CustomL10nIntlService,
  ) {
    this.subscriptions.push(route.params.subscribe(val => {
      this.init();
    }));

  }

  ngOnInit(): void {
  }

  private init(): void {
    this.service = new MenuViewService();

    // Data requests from components
    this.subscriptions.push(
      this.service.getDataRequest().subscribe((date: string) => {
        this.subscriptions.push(
          this.menuService
            .getDateData(this.tenantName, this.siteName, this.menuName, [date])
            .subscribe((dates: MenuPublishDateDataReport[]) => {
              this.service.setData({
                date,
                menuPublishDate: dates[0]?.data,
                noData: dates[0]?.data?.mealOptions == null || dates[0]?.data?.mealOptions.length < 1
              });
              this.showMainError = false;
            }, error => {
              this.showMainError = true;
            }));
      })
    );

    // Url selected in component
    this.subscriptions.push(
      this.service.getUrlChangeRequest().subscribe((url: string) => {
        this.goToMenu(url)
      })
    );

    // Url change
    this.subscriptions.push(this.activatedRoute
      .queryParams
      .subscribe(async params => {

        this.tenantName = this.route.snapshot.paramMap.get('tenantName') as string;
        this.siteName = this.route.snapshot.paramMap.get('siteName') as string;
        this.menuName = this.route.snapshot.paramMap.get('menuName') as string;
        this.date = params['date'] as string ?? getDateAsString(new Date());
        this.isAllOpened = params['openAll'] as string === 'true';
        this.weekend = ((params['weekend'] as string) ?? 'true') === 'true';
        this.theme = params['theme'] as string ?? this.appThemeService.getDefaultTheme();
        const languageFromParameter: string = params['lang'];

        let languageToUse: string;
        if (this.allowedLanguageCodesInOrder.includes(languageFromParameter)) {
          languageToUse = languageFromParameter;
        } else {
          languageToUse = 'fi';
        }
        await this.setLanguage(languageToUse)
        document.documentElement.lang = languageToUse;
        this.initializeTranslations();

        this.appThemeService.setTheme(this.theme);

        const now: Date = new Date();
        const nowMonday: string = getDateAsString(getMondayOfDate(now));
        const nowSunday: string = getDateAsString(getSundayOfDate(now));

        const lastWeekSunday: Date = getDateOnAnotherWeek(nowSunday, -1);
        const nextWeekMonday: Date = getDateOnAnotherWeek(nowMonday, 1);
        const nextWeekSunday: Date = getDateOnAnotherWeek(nowSunday, 1);

        this.currentWeekMonday = getDateAsString(getMondayOfDate(this.date));
        this.currentWeekFriday = getDateAsString(getFridayOfDate(this.date));
        this.currentWeekSunday = getDateAsString(getSundayOfDate(this.date));

        this.isDateOutOfData = isDateLessOrSame(this.date, lastWeekSunday) || isDateGreater(this.date, nextWeekSunday);
        this.isPreviousWeekAvailable = isDateGreater(this.date, nowSunday);
        this.isNextWeekAvailable = isDateLessOrSame(this.date, nowSunday);

        this.menuWeekHeaderDates = { startDate: this.currentWeekMonday, endDate: this.currentWeekSunday }
        this.initializeDates();


        try {
          this.initializeDateData();
        } catch (error) {
          console.error(`Error when initializing date data`);
        }

        try {
          this.updateMenuInformation();
        } catch (error) {
          console.error(`Error when initializing menus`);
        }
      }));
  }

  private async setLanguage(code: string): Promise<void> {
    await this.ts.setLocale({ language: code });
    this.language = code;
  }

  initializeTranslations(): void {
    this.tk.error_fetching_data = this.ts.translate(TK.error_fetching_data);
    this.tk.menu_not_found = this.ts.translate(TK.menu_not_found);
    this.tk.site_not_found = this.ts.translate(TK.site_not_found);
  }

  languageSelected(languageCode: string): void {
    this.goToCurrentMenu(0, languageCode);
  }

  initializeDateData(): void {

    let daysToFetch: string[] = [];
    if (this.isAllOpened === true) {
      daysToFetch = getDatesBetween(this.currentWeekMonday, this.currentWeekSunday).map(day => getDateAsString(day));
    }
    else {
      daysToFetch.push(getDateAsString(this.date));
    }

    this.subscriptions.push(
      this.menuService
        .getDateData(this.tenantName, this.siteName, this.menuName, daysToFetch)
        .subscribe((dates: MenuPublishDateDataReport[]) => {
          dates.forEach(date => {
            this.service.setData({
              date: date?.date,
              menuPublishDate: date.data,
              noData: date?.data?.mealOptions == null || date?.data?.mealOptions.length < 1
            });
          });
          this.showMainError = false;
        }, error => {
          this.showMainError = true;
        }),
    );

    this.subscriptions.push(
      this.menuService
        .getMenuData(this.tenantName, this.siteName, this.menuName)
        .subscribe((menuPublishMenu: MenuPublishMenu) => {
          this.menuPublishMenu = menuPublishMenu;
          const languageCodes: string[] = (this.menuPublishMenu?.menuInformation?.map(information => information.language) ?? [])
            .sort((lang1, lang2) => this.allowedLanguageCodesInOrder.indexOf(lang1) - this.allowedLanguageCodesInOrder.indexOf(lang2));
          this.currentLanguages = languageCodes
            .map(languageCode => { return { name: this.ts.translate(`language_${languageCode}`), code: languageCode } });
          if (!languageCodes.includes(this.language)) {
            this.setLanguage(this.currentLanguages[0].code ?? 'fi')
          }
          this.showMenuNotFound = false;
        }, error => {
          this.showMenuNotFound = true;
        })
    );
  }

  private updateMenuInformation(): void {
    this.subscriptions.push(
      this.menuService
        .getMenus(this.tenantName, this.siteName)
        .subscribe((availableMenus: MenuPublishAvailableMenu[]) => {
          this.availableMenus = availableMenus;
          this.showMainError = false;
          this.showTenantOrSiteNotFound = false;
        }, ((error: GeneralServiceError) => {
          this.showTenantOrSiteNotFound = true;
        }))
    );
  }

  openAll(): void {
    this.goToCurrentMenu(0, null, true);
  }

  goToPreviousWeek(): void {
    this.goToCurrentMenu(-1);
  }

  getUrlWithoutParams() {
    const urlTree: UrlTree = this.router.parseUrl(this.urlDecoder.decode(this.router.url));
    urlTree.queryParams = {};
    urlTree.fragment = null; // optional
    return this.urlDecoder.decode(urlTree.toString());
  }

  goToNextWeek(): void {
    this.goToCurrentMenu(1);
  }

  goToMenu(url: string, languageCode?: string): void {

    const urlTree = this.router.parseUrl(this.router.url);
    urlTree.queryParams = {};
    urlTree.fragment = null; // optional

    const currentUrl: string = this.getUrlWithoutParams();
    const urlRootToGo: string = currentUrl.substring(0, currentUrl.lastIndexOf('/'));
    const urlToGo: string = `${urlRootToGo}/${url}`;

    const weekendText: string = this.weekend === false ? 'false' : undefined;
    const themeText: string = this.theme === this.appThemeService.getDefaultTheme() ? undefined : this.theme;

    this.router.navigate(
      [urlToGo],
      { queryParams: { date: this.currentWeekMonday, lang: languageCode ?? this.language, weekend: weekendText, theme: themeText } }
    );
  }

  goToCurrentMenu(weekDifference: number, languageCode?: string, openAll?: boolean): void {
    const date: string = getDateAsString(getDateOnAnotherWeek(this.currentWeekMonday, weekDifference));
    const openAllToUse: boolean = openAll != null ? openAll : this.isAllOpened;
    const openAllText: string = openAllToUse === true ? 'true' : undefined;
    const weekendText: string = this.weekend === false ? 'false' : undefined;
    const themeText: string = this.theme === this.appThemeService.getDefaultTheme() ? undefined : this.theme;

    this.router.navigate(
      [this.getUrlWithoutParams()],
      { queryParams: { date, lang: languageCode ?? this.language, openAll: openAllText, weekend: weekendText, theme: themeText } }
    );
  }

  private initializeDates(): void {

    this.menuDays = [];
    const dates: Date[] = this.weekend === true ? getDatesBetween(this.currentWeekMonday, this.currentWeekSunday) :
      getDatesBetween(this.currentWeekMonday, this.currentWeekFriday);

    dates.forEach(date => {
      this.menuDays.push({
        date: getDateAsString(date),
        menuPublishDate: null,
      });
    });

    setTimeout(() => {
      if (this.isAllOpened === true) {
        this.accordion.openAll();
      }
    }, 200);
  }

  getLegendData(): Co2Range[] {
    // find a range that could be shown. If no range is found: then there are no co2Values to show
    let ranges: Co2Range[];

    this.menuDays?.forEach(menuDay => {
      if (ranges != null) {
        return;
      }
      menuDay?.menuPublishDate?.mealOptions?.forEach(mealOption => {
        if (ranges != null) {
          return;
        }
        mealOption.rows?.forEach(row => {
          if (ranges != null) {
            return;
          }
          if (row.co2Value != null && row.co2Value?.ranges != null && row.co2Value?.ranges.length > 0) {
            ranges = row.co2Value.ranges;
            return;
          }
        })
      });
    });

    return ranges;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}

export interface MenuDay {
  date: string;
  noData?: boolean;
  menuPublishDate: MenuPublishDate;
}
