<!-- Screen -->
<div class="container__menu-presentation-header-view">
  <h1 class="header__menu-presentation" [style.font-size]="getHeaderFontSize()" [style.margin-bottom]="getZoomPx(8)">{{ nameToShow }}</h1>
  <div *ngIf="_mode === 'currentWeek'" class="container__menu-presentation-date-range public-menu-container-accent"
  [style.font-size]="getDateFontSize()" [style.margin-bottom]="getZoomPx(8)" [style.padding-top]="getZoomPx(7)"
  [style.padding-right]="getZoomPx(8)" [style.padding-bottom]="getZoomPx(7)" [style.padding-left]="getZoomPx(8)"
  [style.border-radius]="getZoomPx(5)">{{ dateRangeToShow }}</div>
  <div *ngIf="infoToShow != null && infoToShow != ''" class="container__menu-presentation-info"
  [style.font-size]="getInfoFontSize()" [style.margin-top]="getZoomPx(6)" [style.margin-bottom]="getZoomPx(6)">{{ infoToShow }}</div>
</div>
<!-- Print -->
<div class="container__menu-presentation-header-print">
  <h1 class="header__menu-presentation--print">{{ nameToShow }}</h1>
  <div *ngIf="_mode === 'currentWeek'" class="container__menu-presentation-date-range--print">{{ dateRangeToShow }}</div>
  <div *ngIf="infoToShow != null && infoToShow != ''" class="container__menu-presentation-info--print">{{ infoToShow }}</div>
</div>
