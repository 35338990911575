import { HttpClient, HttpErrorResponse, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { MenuPublishAvailableMenu, MenuPublishDate, MenuPublishDateDataReport, MenuPublishMenu } from 'src/data/interfaces';
import { isDateGreaterOrSame, isDateLessThanGivenSecondsOld } from 'src/date-functions';
import { AppConfigService } from './app-config-service';
import { GeneralServiceError } from './general-service-error';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  constructor(
    private http: HttpClient,
    private appConfigService: AppConfigService,
  ) {
  }

  getBaseUrl(): string {
    return `${this.appConfigService.getBackEndUrl()}`;
  }

  getMenus(tenantName: string, siteName: string): Observable<MenuPublishAvailableMenu[]> {

    const key: string = `menus_data_${tenantName}-${siteName}`;
    const data: MenuPublishAvailableMenu[] = this.getCacheData(key);
    if (data != null) {
      return of(data);
    }

    let httpParams: HttpParams = new HttpParams();
    return this.http.get<MenuPublishAvailableMenu[]>(
      `${this.getBaseUrl()}/publicmenu/menus/${tenantName}/${siteName}/`,
      {
        params: httpParams,
        withCredentials: false,
        observe: 'response'
      }
    ).pipe(
      map((value: HttpResponse<MenuPublishAvailableMenu[]>): MenuPublishAvailableMenu[] => {
        this.setCacheData(key, value.body);
        return value.body;
      })
    );
  }

  getMenuData(tenantName: string, siteName: string, menuName: string): Observable<MenuPublishMenu> {
    const key: string = `menu_data_${tenantName}-${siteName}-${menuName}`;
    const data: MenuPublishMenu = this.getCacheData(key);
    if (data != null) {
      return of(data);
    }

    let httpParams: HttpParams = new HttpParams();
    httpParams = httpParams.set('menu', menuName);

    return this.http.get<MenuPublishMenu>(
      `${this.getBaseUrl()}/publicmenu/menu/${tenantName}/${siteName}/`,
      {
        params: httpParams,
        withCredentials: false,
        observe: 'response'
      }
    ).pipe(
      map((value: HttpResponse<MenuPublishMenu>): MenuPublishMenu => {
        this.setCacheData(key, value.body);
        return value.body;
      })
    );
  }

  getDateData(tenantName: string, siteName: string, menuName: string, dates: string[]): Observable<MenuPublishDateDataReport[]> {

    const datasFromCache: MenuPublishDateDataReport[] = [];
    let missingDates: string[] = [];

    for (const date of dates) {
      const key: string = `menu_date_data_${tenantName}-${siteName}-${menuName}-${dates}`;
      const data: MenuPublishDateDataReport = this.getCacheData(key);
      if (data != null) {
        datasFromCache.push(data);
      } else {
        missingDates.push(date);
      }
    }

    if (missingDates.length === 0) {
      return of(datasFromCache);
    }

    const toBeReturned: MenuPublishDateDataReport[] = [...datasFromCache];

    let httpParams: HttpParams = new HttpParams();
    return this.http.get<MenuPublishDateDataReport[]>(
      `${this.getBaseUrl()}/publicmenu/dates/${tenantName}/${siteName}/?menu=${menuName}&dates=${dates.join(',')}`,
      {
        params: httpParams,
        withCredentials: false,
        observe: 'response'
      }
    ).pipe(
      map((values: HttpResponse<MenuPublishDateDataReport[]>): MenuPublishDateDataReport[] => {
        const menuPublishDatesReceived: MenuPublishDateDataReport[] = values.body ?? [];
        for (const value of menuPublishDatesReceived) {
          const key: string = `menu_date_data_${tenantName}-${siteName}-${menuName}-${value.date}`;
          this.setCacheData(key, value);
        }
        return [...toBeReturned, ...values.body];
      })
    );
  }

  getCacheData(key: string): any {
    try {
      const stringData: string = sessionStorage.getItem(key);
      const cacheData: CacheData = JSON.parse(stringData);
      const timestamp: Date = new Date(cacheData?.timestamp);
      const minutes15InSeconds: number = 900;
      if (isDateLessThanGivenSecondsOld(timestamp, minutes15InSeconds)) {
        return JSON.parse(cacheData.data);
      }
      return null;
    } catch (error) {
      console.error(`Error fetching data (key: ${key}) from cache: ${error}`);
      return null;
    }
  }

  setCacheData(key: string, data: any): void {
    try {
      const cacheDataToSet: CacheData = {
        data: JSON.stringify(data),
        timestamp: new Date().getTime()
      }
      sessionStorage.setItem(key, JSON.stringify(cacheDataToSet));
    } catch (error) {
      console.error(`Error setting data (key: ${key}) to cache: ${error}`);
      return null;
    }
  }
}

export function errorHandler(error: HttpErrorResponse): Observable<GeneralServiceError> {
  if (error.error instanceof ErrorEvent) {
    // A client-side or network error occurred. Handle it accordingly.
    console.error('An error occurred:', error.error.message);
  } else {
    // The backend returned an unsuccessful response code.
    // The response body may contain clues as to what went wrong,
    console.error(
      `Backend returned code ${error.status}, ` +
      `body was: ${JSON.stringify(error.error)}`);
  }
  // return an observable with a user-facing error message
  return of({
    successful: false,
    status: error.status,
    noRight: error.status === 403,
    notFound: error.status === 404
  } as GeneralServiceError);
}

export class ServiceAnswerGetSingle<DATA_TYPE = any> {
  successful: boolean;
  found?: boolean;
  data?: DATA_TYPE;
}

interface CacheData {
  data: string;
  timestamp: number;
}

