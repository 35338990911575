import { APP_INITIALIZER, Injectable, NgModule, Optional } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClient, HttpClientModule, HttpHeaders } from '@angular/common/http';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { MatStepperModule } from '@angular/material/stepper';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { L10nConfig, L10nIntlModule, L10nLoader, L10nProvider, L10nTranslationLoader, L10nTranslationModule } from 'angular-l10n';
import { Observable } from 'rxjs';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ViewNutritiveTableComponent } from './components/view-nutritive-table-question/view-nutritive-table.component';
import { MenuDayRowInfoDialogComponent } from './menu-day-row-info-dialog/menu-day-row-info-dialog.component';
import { MenuDayComponent } from './menu-day/menu-day.component';
import { MenuLanguageBarComponent } from './menu-language-bar/menu-language-bar.component';
import { MenuSelectionComponent } from './menu-selection/menu-selection.component';
import { MenuViewComponent } from './menu-view/menu-view.component';
import { MenuWeekHeaderComponent } from './menu-week-header/menu-week-header.component';
import { MenuPresentationComponent } from './menu-presentation/menu-presentation.component';
import { MenuPresentationHeaderComponent } from './menu-presentation-header/menu-presentation-header.component';
import { MenuPresentationDayComponent } from './menu-presentation-day/menu-presentation-day.component';
import { HelpComponent } from './help/help.component';
import { AppConfigService } from './services/app-config-service';
import { AppThemeService } from './services/app-theme.service';
import { Co2RangeViewComponent } from './components/view-co2-value/co2-range-view/co2-range-view.component';
import { ViewCo2ValueComponent } from './components/view-co2-value/view-co2-value.component';
import { ViewCo2ValueRowComponent } from './components/view-co2-value-row/view-co2-value-row.component';
import { ViewCo2LegendComponent } from './components/view-co2-legend/view-co2-legend.component';
import { ViewCo2IconMiniRangeComponent } from './components/view-co2-mini-range/view-co2-icon-mini-range.component';


@Injectable() export class HttpTranslationLoader implements L10nTranslationLoader {

  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(@Optional() private http: HttpClient) { }

  public get(language: string, provider: L10nProvider): Observable<{ [key: string]: any }> {
    const url = `${provider.asset}${language}.json`;
    const options = {
      headers: this.headers,
    };
    return this.http.get(url, options);
  }

}

const l10nConfig: L10nConfig = {
  format: 'language-region',
  keySeparator: '.',
  providers: [
    { name: 'app', asset: './assets/translations/' },
  ],
  defaultLocale: { language: 'fi', currency: 'EUR' },
  schema: [
    { locale: { language: 'en', currency: 'EUR' }, dir: 'ltr', text: 'Gb' },
    { locale: { language: 'fi', currency: 'EUR' }, dir: 'ltr', text: 'Finnish' }
  ],
  cache: true,
};


const appInitializerFn = (appConfig: AppConfigService, l10nLoader: L10nLoader) => {
  return async (): Promise<void> => {
    await appConfig.loadAppConfig();

    await l10nLoader.init();
  }
};

@NgModule({
  declarations: [
    AppComponent,
    MenuDayComponent,
    MenuDayRowInfoDialogComponent,
    MenuSelectionComponent,
    MenuWeekHeaderComponent,
    MenuViewComponent,
    MenuPresentationComponent,
    MenuPresentationHeaderComponent,
    MenuPresentationDayComponent,
    ViewNutritiveTableComponent,
    ViewCo2ValueComponent,
    Co2RangeViewComponent,
    ViewCo2ValueRowComponent,
    MenuLanguageBarComponent,
    ViewCo2LegendComponent,
    ViewCo2IconMiniRangeComponent,
    HelpComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatSliderModule,
    MatFormFieldModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    MatButtonModule,
    MatIconModule,
    MatTreeModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatInputModule,
    MatTableModule,
    MatSelectModule,
    MatCheckboxModule,
    MatCardModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatExpansionModule,
    MatDividerModule,
    MatStepperModule,
    L10nIntlModule,
    L10nTranslationModule.forRoot(
      l10nConfig,
      {
        translationLoader: HttpTranslationLoader
      }
    ),
  ],
  providers: [{
    provide: APP_INITIALIZER,
    useFactory: appInitializerFn,
    multi: true,
    deps: [AppConfigService, L10nLoader, AppThemeService]
  },
    // { provide: UrlSerializer, useClass: CustomUrlSerializer }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }


