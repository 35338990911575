import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { MenuDay } from './menu-view.component';
import { Co2Range } from '../components/view-co2-value/co2-range-view/co2-range-view.component';

@Injectable({
  providedIn: 'root'
})
export class MenuViewService {

  /**
   * Request date data
   *
   * @private
   * @type {Subject<string>}
   * @memberof MenuViewService
   */
  private _subjectDataRequest: Subject<string> = new Subject<string>();

  /**
   * Data is available
   *
   * @private
   * @type {Subject<MenuDay>}
   * @memberof MenuViewService
   */
  private _subjectData: Subject<MenuDay> = new Subject<MenuDay>();
  private _subjectUrlChangeRequest: Subject<string> = new Subject<string>();

  constructor(
  ) {
  }

  public getDataRequest(): Observable<string> {
    return this._subjectDataRequest;
  }

  public requestData(date: string): void {
    this._subjectDataRequest.next(date);
  }


  public getData(): Observable<MenuDay> {
    return this._subjectData;
  }

  public setData(menuDay: MenuDay): void {
    this._subjectData.next(menuDay);
  }

  public getUrlChangeRequest(): Observable<string> {
    return this._subjectUrlChangeRequest;
  }

  public setUrlChangeRequest(url: string): void {
    this._subjectUrlChangeRequest.next(url);
  }
}