import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { L10nTranslationService } from 'angular-l10n';
import { getCo2RangeValueFormatted } from 'src/app/tools/co2-tools';
import { CustomL10nIntlService } from 'src/data/custom-locale.service';

@Component({
  selector: 'app-co2-range-view',
  templateUrl: './co2-range-view.component.html',
  styleUrls: ['./co2-range-view.component.scss']
})
export class Co2RangeViewComponent implements OnInit {

  public ranges: Co2RangeUI[] = [];
  public value: number;

  public cursorCalculatedLocation: string = '0%';
  public cursorWithinRange: boolean = false;
  public cursorColor: string;

  @Input() set co2Value(co2Value: string) {
    this.value = co2Value != null ? +co2Value : undefined;
    this.initValue();
  }

  @Input() set co2Ranges(ranges: Co2Range[]) {
    if (ranges == null) {
      return;
    }
    this.ranges.push(...ranges);
    this.initValue();
  }

  constructor(
    public customLocaleService: CustomL10nIntlService,
    private ts: L10nTranslationService,
  ) {

  }

  ngOnInit(): void {
    this.initValue();
  }

  initValue() {
    if (this.ranges == null || this.ranges.length === 0) {
      return;
    }

    this.ranges.forEach((range: Co2RangeUI, index: number) => {

      range.last = index + 1 === this.ranges.length;
      range.withinRange = this.value != null ? Co2RangeViewComponent.isWithinRange(this.value, range, range.last) : false;
      range.minFormatted = getCo2RangeValueFormatted(range.min, this.customLocaleService);
      range.maxFormatted = getCo2RangeValueFormatted(range.max, this.customLocaleService);
      range.first = index === 0;
      range.cursorRelativeLocation = range.withinRange === true ? this.calculateRelativeLocation(range) : undefined;
      range.name = this.ts.translate(range.nameKey);
      range.colorLight = range.color + 'aa';
      range.colorLighter = range.color + '77';
      range.colorVeryLight = range.color + '33';

      if (range.withinRange === true) {
        this.cursorCalculatedLocation = this.calculateAbsoluteLocation(range.cursorRelativeLocation, index)
        this.cursorWithinRange = true;
        this.cursorColor = range.color;
      }
    })

    // no value hide cursor
    if (this.ranges.find(range => range.withinRange === true) == null) {
      this.cursorColor = '#ffffff00';
    }
  }

  public static isWithinRange(value: number, range: Co2Range, isLast: boolean): boolean {
    // 0 would mean that there is no calculated value
    if (value == 0) {
      return false;
    }

    return value >= range.min && (value < range.max || isLast === true);
  }

  /**
   * Position relative to the range
   * @param range 
   * @returns 
   */
  private calculateRelativeLocation(range: Co2RangeUI): number {
    try {
      let calculatedValue: number = +((this.value - range.min) / (range.max - range.min));

      // just to be sure
      if (calculatedValue > 1) {
        calculatedValue = 1;
      } else if (calculatedValue < 0) {
        calculatedValue = 0;
      }
      return calculatedValue;
    } catch (error) {
      console.error('Error: ' + error);
      return 0;
    }
  }

  /**
   * Absolute position relative to all ranges
   * @param cursorRelativeLocation 
   * @param rangePercentageSize 
   * @param index 
   * @returns 
   */
  private calculateAbsoluteLocation(cursorRelativeLocation: number, index: number): string {
    try {
      const rangePercentageSize: number = 100 / this.ranges.length;

      let absoluteLocation: number = index * rangePercentageSize + cursorRelativeLocation * rangePercentageSize;

      // just to be sure
      if (absoluteLocation > 100) {
        absoluteLocation = 100;
      } else if (absoluteLocation < 0) {
        absoluteLocation = 0;
      }

      return `${absoluteLocation.toFixed(2)}%`
    } catch (error) {
      console.error('Error: ' + error);
      return '0%';
    }
  }
}

export interface Co2Range {
  min: number;
  max: number;
  nameKey: string;
  color: string; // hex
}

export interface Co2RangeUI extends Co2Range {
  name?: string;
  withinRange?: boolean;
  minFormatted?: string;
  maxFormatted?: string;
  first?: boolean;
  last?: boolean;
  cursorRelativeLocation?: number; // %
  colorLight?: string;
  colorLighter?: string;
  colorVeryLight?: string;
}