import { Component, EventEmitter, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { L10nTranslationService } from 'angular-l10n';
import { Subscription } from 'rxjs';
import { TK } from 'src/data/translation-keys';
import { MenuPublishRow } from '../../data/interfaces';
import { UiRow } from '../menu-day/menu-day.component';

@Component({
  selector: 'app-menu-day-row-info-dialog',
  templateUrl: './menu-day-row-info-dialog.component.html',
  styleUrls: ['./menu-day-row-info-dialog.component.scss']
})
export class MenuDayRowInfoDialogComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = [];

  public title: string = '';
  public language: string;
  public row: UiRow;

  onClose = new EventEmitter<void>();

  tk = {
    allergens: '',
    diets: '',
    ingredients: '',
    carbonFootprint: '',
    close: '',
  }

  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: MenuDayRowInfoDialogData,
    public ts: L10nTranslationService
  ) {
    this.row = data.row;
    this.language = data.language;

    this.tk.allergens = this.ts.translate(TK.allergens);
    this.tk.diets = this.ts.translate(TK.diets);
    this.tk.ingredients = this.ts.translate(TK.ingredients);
    this.tk.close = this.ts.translate(TK.close);
    this.tk.carbonFootprint = this.ts.translate(TK.carbon_footprint);
  }

  ngOnInit(): void {
  }


  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}

export interface MenuDayRowInfoDialogData {
  row: UiRow;
  language: string;
}