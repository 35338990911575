<!-- Screen -->
<div class="container__menu-day-view">
  <mat-expansion-panel class="public-menu-container-border" (opened)="opened()" (closed)="closed()"
    [expanded]="expandedDefault">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div [ngClass]="getDateStyle()">{{ dateFormatted }}</div>
      </mat-panel-title>
      <div *ngIf="processInProgress" class="container__menu-day-progress">
        <mat-spinner diameter="30" color="accent"></mat-spinner>
      </div>
    </mat-expansion-panel-header>

    <div *ngIf="_menuDay.noData == true && processInProgress != true" class="container__menu-day-no-data">{{
      tk.no_information }}</div>

    <ng-container *ngFor="let mealOption of uiMealOptions">
      <h2 class="header__menu-day-meal-option">{{ mealOption.name }}</h2>
      <ng-container *ngFor="let row of mealOption.rows">
        <div class="container__menu-day-row">
          <div class="container__menu-day-row-name">{{ row.name }}</div>
          <div *ngIf="row.dietShort != null" class="container__menu-day-diets">
            <div *ngFor="let dietShort of row.dietShort" class="container__menu-day-diet public-menu-container-accent">
              {{ dietShort }}
            </div>
          </div>

          <app-view-co2-value-row [value]="row.co2Value" [showUnit]="false"></app-view-co2-value-row>

          <div class="container__menu-day-info-button">
            <button mat-flat-button color="accent" (click)="openRowInfo(row)">{{ tk.info }}</button>
          </div>
        </div>
        <div *ngIf="row.publicInfo != null && row.publicInfo != ''" class="container__menu-day-info">{{ row.publicInfo
          }}
        </div>
        <mat-divider></mat-divider>
      </ng-container>
      <div class="container__menu-day-spacer"></div>
    </ng-container>
  </mat-expansion-panel>
</div>
<!-- Print -->
<div *ngIf="_menuDay.noData != true && processInProgress != true" class="container__menu-day-print">
  <h2 class="header__menu-day-date--print">{{ dateFormatted }}</h2>
  <ng-container *ngFor="let mealOption of uiMealOptions">
    <h3 class="header__menu-day-meal-option--print">{{ mealOption.name }}</h3>
    <ng-container *ngFor="let row of mealOption.rows">
      <div class="container__menu-day-row--print">
        <div class="container__menu-day-row-name--print">{{ row.name }}</div>
        <div *ngIf="row.dietShort != null" class="container__menu-day-diets--print">
          <div *ngFor="let dietShort of row.dietShort" class="container__menu-day-diet--print">
            {{ dietShort }}
          </div>
        </div>

        <app-view-co2-value-row [value]="row.co2Value" [showUnit]="false"></app-view-co2-value-row>
      </div>
      <div *ngIf="row.publicInfo != null && row.publicInfo !== ''" class="container__menu-day-info--print">{{
        row.publicInfo }}</div>
      <hr />
    </ng-container>
  </ng-container>
  <app-view-co2-legend *ngIf="legendRanges != null" [bottomHeight]="'0'" [ranges]="legendRanges"></app-view-co2-legend>
</div>