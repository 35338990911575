<div class="container__main">

  <div *ngIf="co2Value?.valueFormatted != null" class="container__menu-day-co2" [matTooltip]="tooltipText"
    [matTooltipClass]="'tooltip-multiline'">
    <div *ngIf="isShowUnitAndValue" class="container__menu-day-co2-value">
      <span class="co2-value" [style.font-size]="getZoomedSize(1, 'em')">{{ co2Value.valueFormatted }}</span>
      <span *ngIf="isShowUnit" class="co2-unit" [style.font-size]="getZoomedSize(1, 'em')">{{tk.co2_unit_short}}
      </span>
    </div>
    <div class="container__icon-with-range">

      <app-co2-icon-with-mini-range
        [miniRangeData]="{ activeRange: rangeWithin, activeRangeIndex: currentRangeIndex, ranges: co2Value.ranges}"
        [zoomFactor]="_zoomFactor">
      </app-co2-icon-with-mini-range>
    </div>
    <span class="cdk-visually-hidden">{{tk.carbon_footprint}} {{rangeWithin?.name ?? ''}}:
      {{this.co2Value?.valueFormatted ?? ''}}
      {{this.tk.co2_unit}}
    </span>
  </div>

  <!-- placeholder -->
  <div *ngIf="co2Value?.valueFormatted == null" class="container__menu-day-co2">
  </div>

</div>