import { Component, Input, OnInit } from '@angular/core';
import { L10nTranslationService } from 'angular-l10n';
import { CustomL10nIntlService, FormatType } from 'src/data/custom-locale.service';
import { MenuPublishMenu } from 'src/data/interfaces';
import { MenuPresentationMode } from '../menu-presentation/menu-presentation.component';

@Component({
  selector: 'app-menu-presentation-header',
  templateUrl: './menu-presentation-header.component.html',
  styleUrls: ['./menu-presentation-header.component.scss']
})
export class MenuPresentationHeaderComponent implements OnInit {

  _menuPublishMenu!: MenuPublishMenu;
  @Input() set menuPublishMenu(menuPublishMenu: MenuPublishMenu) {
    this._menuPublishMenu = menuPublishMenu;
    this.initializeMenuNames();
  }

  _menuWeekHeaderDates!: MenuPresentationHeaderDates;
  @Input() set menuWeekHeaderDates(menuWeekHeaderDates: MenuPresentationHeaderDates) {
    this._menuWeekHeaderDates = menuWeekHeaderDates;
    this.initializeMenuDates();
  }

  _mode!: MenuPresentationMode;
  @Input() set mode(mode: MenuPresentationMode) {
    this._mode = mode;
  }

  _language!: string;
  @Input() set language(language: string) {
    this._language = language;
    this.initializeMenuNames();
    this.initializeMenuDates();
  }

  _zoomFactor!: number;
  @Input() set zoomFactor(zoomFactor: number) {
    this._zoomFactor = zoomFactor;
  }

  public nameToShow!: string;
  public infoToShow!: string;
  public dateRangeToShow!: string;

  constructor(
    private customLocaleService: CustomL10nIntlService,
    private ts: L10nTranslationService
  ) { }

  ngOnInit(): void {
  }

  private initializeMenuNames(): void {

    if (this._menuPublishMenu == null || this._language == null) {
      return;
    }

    this.nameToShow = (this._menuPublishMenu.menuNames ?? []).find(name => name.language === this._language)?.name ?? '';
    this.infoToShow = (this._menuPublishMenu.menuInformation ?? []).find(info => info.language === this._language)?.information ?? '';
  }

  private initializeMenuDates(): void {

    if (this._menuWeekHeaderDates == null || this._language == null) {
      return;
    }

    const startDateFormatted: string = this._menuWeekHeaderDates.startDate != null ?
      this.customLocaleService.format(this._menuWeekHeaderDates.startDate, FormatType.formatDate) :
      '';

    const endDateFormatted: string = this._menuWeekHeaderDates.endDate != null ?
      this.customLocaleService.format(this._menuWeekHeaderDates.endDate, FormatType.formatDate) :
      '';

    this.dateRangeToShow = `${startDateFormatted} - ${endDateFormatted}`;
  }

  public getHeaderFontSize(): string {
    return `calc(1.7em * ${this._zoomFactor})`;
  }

  public getDateFontSize(): string {
    return `calc(1.2em * ${this._zoomFactor})`;
  }

  public getInfoFontSize(): string {
    return `calc(1em * ${this._zoomFactor})`;
  }

  public getZoomPx(pixels: number): string {
    return `calc(${pixels}px * ${this._zoomFactor})`;
  }
  
}

export interface MenuPresentationHeaderDates {
  startDate: string;
  endDate: string;
}