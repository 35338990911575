<div *ngIf="showMainError" class="container__menu-view-message public-menu-container-error">
  {{ tk.error_fetching_data }}
</div>
<div *ngIf="showTenantOrSiteNotFound" class="container__menu-view-message public-menu-container-warning">
  {{ tk.site_not_found }}
</div>
<div *ngIf="!showTenantOrSiteNotFound && showMenuNotFound"
  class="container__menu-view-message public-menu-container-warning">
  {{ tk.menu_not_found }}
</div>
<!-- Screen -->
<div class="container__menu-view">

  <div class="container__menu-view-header public-menu-container-border">
    <app-menu-week-header [language]="language" [menuPublishMenu]="menuPublishMenu"
      [isNextWeekAvailable]="isNextWeekAvailable" [isPreviousWeekAvailable]="isPreviousWeekAvailable"
      (moveToNextWeek)="goToNextWeek()" (moveToPreviousWeek)="goToPreviousWeek()" (expandAllDays)="openAll()"
      [menuWeekHeaderDates]="menuWeekHeaderDates" [service]="service">
    </app-menu-week-header>
    <app-menu-selection [language]="language" [availableMenus]="availableMenus" [service]="service"
      [currentMenuName]="menuName">
    </app-menu-selection>
  </div>

  <mat-accordion multi="true" #accordion="matAccordion" [displayMode]="'flat'">
    <app-menu-day *ngFor="let menuDay of menuDays" [menuDay]="menuDay" [language]="language" [service]="service"
      [noDataRequest]="false">
    </app-menu-day>
  </mat-accordion>

  <app-menu-language-bar (languageSelected)="languageSelected($event)" [language]="language"
    [languages]="currentLanguages">
  </app-menu-language-bar>

</div>
<!-- Print -->
<div class="container__menu-print">
  <app-menu-week-header [language]="language" [menuPublishMenu]="menuPublishMenu"
    [isNextWeekAvailable]="isNextWeekAvailable" [isPreviousWeekAvailable]="isPreviousWeekAvailable"
    (moveToNextWeek)="goToNextWeek()" (moveToPreviousWeek)="goToPreviousWeek()" (expandAllDays)="openAll()"
    [menuWeekHeaderDates]="menuWeekHeaderDates" [service]="service">
  </app-menu-week-header>

  <div class="container__menu-days--print">
    <app-menu-day *ngFor="let menuDay of menuDays" [menuDay]="menuDay" [language]="language" [service]="service"
      [noDataRequest]="true">
    </app-menu-day>
  </div>
</div>