<div *ngIf="_ranges != null" class="container__main" [style.bottom]="_bottomHeight"
  [style.margin-bottom]="getZoomedSize(3, 'px')">

  <span class="align-center" style="margin-right: 10px;"
    [style.font-size]="getZoomedSize(1.15, 'em')">{{tk.carbon_footprint}}: </span>
  <span class="container__data align-center" *ngFor="let rangeData of _ranges; index as i_range">

    <span class="align-center" [style.font-size]="getZoomedSize(1.15, 'em')">
      {{rangeData.name}}
    </span>

    <app-co2-icon-with-mini-range [zoomFactor]="_zoomFactor" [style.margin-bottom]="getZoomedSize(2, 'px')"
      [miniRangeData]="{ activeRange: rangeData, activeRangeIndex: i_range, ranges: _ranges }">
    </app-co2-icon-with-mini-range>

  </span>
</div>