<div class="container__help">
  <div class="container__help-content public-menu-container-border">
  <h1 class="header__help">Mealdoo Configurator</h1>
  <form [formGroup]="configurationForm" (ngSubmit)="openUrl()">
    <mat-stepper orientation="vertical" linear color="accent" #stepper>
      <mat-step formGroupName="basic" [stepControl]="configurationForm.get('basic')">
        <ng-template matStepLabel>Basic Settings</ng-template>
        <div class="container__help-options"><label class="label__help">URL Ids</label></div>
        <div>
          <mat-form-field appearance="fill">
            <mat-label>Tenant id of the URL</mat-label>
            <input matInput placeholder="Tenant id of the URL" formControlName="tenant">
          </mat-form-field>
        </div>
        <div>
          <mat-form-field appearance="fill">
            <mat-label>Site id of the URL</mat-label>
            <input matInput placeholder="Site id of the URL" formControlName="site">
          </mat-form-field>
        </div>
        <div>
          <mat-form-field appearance="fill">
            <mat-label>Menu id of the URL</mat-label>
            <input matInput placeholder="Menu id of the URL" formControlName="menu">
          </mat-form-field>
        </div>
        <mat-card appearance="outlined" class="help__card public-menu-container-border">
          <mat-card-content>
            <p>Check your tenant, site and menu URL ids in the menu publishing settings of the menu to be published.</p>
          </mat-card-content>
        </mat-card>
        <div>
          <div class="container__help-option"><label class="label__help" for="view">View Mode</label></div>
          <mat-radio-group class="help__radio-group" id="view" formControlName="view">
            <mat-radio-button class="help__radio-button" value="week">Week</mat-radio-button>
            <mat-radio-button class="help__radio-button" value="presentation">Presentation</mat-radio-button>
          </mat-radio-group>
          <mat-card appearance="outlined" class="help__card public-menu-container-border">
            <mat-card-content>
              <p>Week: Interactive weekly view for mobile devices and as a link on web sites. Customers can view the current and next week's menu. Includes diets, allergens and nutritional content.</p>
              <p>Presentation: Read only view for digital signages and as a link or embedded on web sites. Customers can view the menu for the current day or the current week. Includes diets.</p>
            </mat-card-content>
          </mat-card>
        </div>
        <div>
          <button mat-flat-button color="accent" matStepperNext type="button" style="margin-top: 20px;">Next</button>
        </div>
      </mat-step>
      <mat-step formGroupName="content" [stepControl]="configurationForm.get('content')">
        <ng-template matStepLabel>Content</ng-template>
        <div *ngIf="getView() === 'presentation'">
          <div class="container__help-option"><label class="label__help" for="mode">Mode</label></div>
          <div>
            <mat-radio-group class="help__radio-group" id="mode" formControlName="mode">
              <mat-radio-button class="help__radio-button" value="currentDate">Current date (default)</mat-radio-button>
              <mat-radio-button class="help__radio-button" value="currentWeek">Current week</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        <div>
          <div class="container__help-option"><label class="label__help" for="lang">Language</label></div>
          <div>
            <mat-radio-group class="help__radio-group" id="lang" formControlName="lang">
              <mat-radio-button class="help__radio-button" value="fi">Finnish (default)</mat-radio-button>
              <mat-radio-button class="help__radio-button" value="sv">Swedish</mat-radio-button>
              <mat-radio-button class="help__radio-button" value="en">English</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        <div class="container__help-options">
          <div class="container__help-checkbox">
            <mat-checkbox formControlName="weekend">
              Include weekend (default)
            </mat-checkbox>
          </div>
          <div *ngIf="getView() === 'week'" class="container__help-checkbox">
            <mat-checkbox formControlName="openAll">
              Expand all weekdays
            </mat-checkbox>
          </div>
        </div>
        <div>
          <button mat-flat-button color="accent" matStepperPrevious type="button">Back</button>
          <button class="button__help-next" mat-flat-button color="accent" matStepperNext type="button">Next</button>
        </div>
      </mat-step>
      <mat-step formGroupName="appearance" [stepControl]="configurationForm.get('appearance')">
        <ng-template matStepLabel>Appearance</ng-template>
        <div *ngIf="getView() === 'presentation'">
          <div class="container__help-option"><label class="label__help" for="orientation">Orientation</label></div>
          <mat-radio-group class="help__radio-group" id="orientation" formControlName="orientation">
            <mat-radio-button class="help__radio-button" value="portrait">Portrait (default)</mat-radio-button>
            <mat-radio-button class="help__radio-button" value="landscape">Landscape</mat-radio-button>
          </mat-radio-group>
          <mat-card appearance="outlined" class="help__card public-menu-container-border">
            <mat-card-content *ngIf="getMode() === 'currentDate'">
              <p>Portrait: Meal options in one column.</p>
              <p>Landscape: Meal options in 1-3 columns.</p>
            </mat-card-content>
            <mat-card-content *ngIf="getMode() === 'currentWeek'">
              <p>Portrait: Days as rows.</p>
              <p>Landscape: Days as columns.</p>
            </mat-card-content>
          </mat-card>
        </div>
        <div *ngIf="getView() === 'presentation'">
          <div class="container__help-options"><label class="label__help">Zoom</label></div>
          <mat-form-field appearance="fill">
            <mat-label>Zoom</mat-label>
            <input matInput placeholder="Zoom" formControlName="zoom">
            <span matTextSuffix>&nbsp;%</span>
          </mat-form-field>
        </div>
        <div>
          <div class="container__help-options"><label class="label__help">Theme</label></div>
          <mat-form-field appearance="fill">
            <mat-label>Theme</mat-label>
            <mat-select formControlName="theme" name="theme" (selectionChange)="themeChange($event)">
              <mat-option *ngFor="let theme of themes" [value]="theme.id">
                {{ theme.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div>
          <button mat-flat-button color="accent" matStepperPrevious type="button">Back</button>
          <button class="button__help-next" mat-flat-button color="accent" matStepperNext type="submit"
          [disabled]="openDisabled">Test</button>
        </div>
      </mat-step>
    </mat-stepper>
  </form>
  </div>
</div>