import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import { L10nTranslationService } from "angular-l10n";
import { TK } from "src/data/translation-keys";
import { Co2RangeUI } from "./co2-range-view/co2-range-view.component";
import { Co2ValueUI } from "src/app/tools/co2-tools";

@Component({
  selector: 'app-view-co2-value',
  templateUrl: './view-co2-value.component.html',
  styleUrls: ['./view-co2-value.component.scss']
})
export class ViewCo2ValueComponent implements OnDestroy {

  public co2Value: Co2ValueUI;

  public valueColor: string = '#ffffff';

  public static _ranges: Co2RangeUI[];
  public ranges: Co2RangeUI[];

  @Input() set value(value: Co2ValueUI) {
    this.init(value);
  }

  constructor(
    ts: L10nTranslationService,
  ) {
    this.tk.carbon_footprint = ts.translate(TK.carbon_footprint);
    this.tk.very_low = ts.translate(TK.very_low);
    this.tk.low = ts.translate(TK.low);
    this.tk.medium = ts.translate(TK.medium);
    this.tk.high = ts.translate(TK.high);
    this.tk.very_high = ts.translate(TK.very_high);
    this.tk.co2_unit = ts.translate(TK.co2_unit);
  }

  public tk = {
    carbon_footprint: '',
    very_low: '',
    low: '',
    medium: '',
    high: '',
    very_high: '',
    co2_unit: '',
  };

  ngOnDestroy(): void {

  }

  public init(co2Value?: Co2ValueUI): void {
    if (!this.canInit(co2Value)) {
      return;
    }
    this.co2Value = co2Value;

    this.initContent();
  }

  canInit(co2Value: Co2ValueUI): boolean {
    return co2Value != null && co2Value.valueFormatted != null;
  }

  private async initContent(): Promise<void> {

  }
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
