<div class="container__row-info-dialog" style="display: flex; flex-direction: column;">

  <div class="container__row-info-content">
    <div class="container__row-info-header public-menu-container">
      <h1 class="header__row-info-main">{{ row?.name }}</h1>
    </div>

    <h2 class="header__row-info-sub--first">{{ tk.ingredients }}</h2>
    <div class="container__row-info-text">
      {{ row?.ingredients }}
    </div>

    <h2 class="header__row-info-sub">{{ tk.allergens }}</h2>
    <ng-container *ngFor="let allergen of row?.allergens">
      <div class="container__row-info-allergen">{{ allergen }}</div>
      <mat-divider></mat-divider>
    </ng-container>

    <ng-container *ngIf="row?.showDiets">
      <h2 class="header__row-info-sub">{{ tk.diets }}</h2>
      <ng-container *ngFor="let diet of row?.diets">
        <div class="container__row-info-diet">{{ diet }}</div>
        <mat-divider></mat-divider>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="row?.co2Value != null && row?.co2Value.valueFormatted != null">
      <h2 class="header__row-info-sub">{{ tk.carbonFootprint }}</h2>

      <app-view-co2-value [value]="row.co2Value"></app-view-co2-value>
    </ng-container>

    <app-view-nutritive-table [language]="language" [nutritiveItem]="row?.nutritive"></app-view-nutritive-table>
  </div>

  <div class="container__row-info-bottom">
    <button class="button__row-info-close" mat-flat-button color="accent" (click)="dialogRef.close()">
      {{ tk.close }}
    </button>
  </div>

</div>