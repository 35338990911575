import { Component, Input, OnInit } from '@angular/core';
import { MenuPublishAvailableMenu, MenuPublishMenu } from 'src/data/interfaces';
import { MenuViewService } from '../menu-view/menu-view.service';

@Component({
  selector: 'app-menu-selection',
  templateUrl: './menu-selection.component.html',
  styleUrls: ['./menu-selection.component.scss']
})
export class MenuSelectionComponent implements OnInit {

  constructor() { }

  _availableMenus!: MenuPublishAvailableMenu[];
  @Input() set availableMenus(availableMenus: MenuPublishAvailableMenu[]) {
    this._availableMenus = availableMenus;
    this.initializeData();
  }

  _language!: string;
  @Input() set language(language: string) {
    this._language = language;
    this.initializeData();
  }

  _service!: MenuViewService;
  @Input() set service(service: MenuViewService) {
    this._service = service;
  }

  _currentMenuName!: string;
  @Input() set currentMenuName(menuName: string) {
    this._currentMenuName = menuName;
  }

  _names: MenuSelectionName[];

  ngOnInit(): void {
  }

  private initializeData(): void {

    const namesToSet: MenuSelectionName[] = [];
    (this._availableMenus ?? []).forEach(menu => {
      namesToSet.push({
        name: this.getMenuName(menu),
        url: menu.publishUrl
      });
    });

    this._names = namesToSet;

  }

  getMenuName(menu: MenuPublishAvailableMenu): string {
    return menu.menuNames?.find(name => name.language === this._language)?.name;
  }

  urlSelected(url: string): void {
    this._service.setUrlChangeRequest(url);
  }
}

interface MenuSelectionName {
  name: string;
  url: string;
}