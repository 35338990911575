export enum TK {
  test = 'test',
  yes = 'yes',
  no = 'not',
  nutritive_factor = 'nutritive_factor',
  next_week = 'next_week',
  previous_week = 'previous_week',
  no_information = "no_information",
  expand_all_days = "expand_all_days",
  info = "info",
  allergens = "allergens",
  diets = "diets",
  ingredients = "ingredients",
  nutritive_factor_value = "nutritive_factor_value",
  reference_intake_short = "reference_intake_short",
  nutrition_table_information = "nutrition_table_information",
  close = "close",
  reference_intake_info = "reference_intake_info",
  error_fetching_data = "error_fetching_data",
  site_not_found = "site_not_found",
  menu_not_found = "menu_not_found",
  carbon_footprint = "carbon_footprint",
  very_low = "very_low",
  low = "low",
  medium = "medium",
  high = "high",
  very_high = "very_high",
  co2_unit = "co2_unit",
  co2_unit_short = "co2_unit_short",
}