export class UrlDecoder {
  private chars: UrlDecoderChar[] = [
    { char: 'Ø', windows1252: '%D8', utf8: '%C3%98' },
    { char: 'Ù', windows1252: '%D9', utf8: '%C3%99' },
    { char: 'Ú', windows1252: '%DA', utf8: '%C3%9A' },
    { char: 'Û', windows1252: '%DB', utf8: '%C3%9B' },
    { char: 'Ü', windows1252: '%DC', utf8: '%C3%9C' },
    { char: 'Ý', windows1252: '%DD', utf8: '%C3%9D' },
    { char: 'Þ', windows1252: '%DE', utf8: '%C3%9E' },
    { char: 'ß', windows1252: '%DF', utf8: '%C3%9F' },
    { char: 'à', windows1252: '%E0', utf8: '%C3%A0' },
    { char: 'á', windows1252: '%E1', utf8: '%C3%A1' },
    { char: 'â', windows1252: '%E2', utf8: '%C3%A2' },
    { char: 'ã', windows1252: '%E3', utf8: '%C3%A3' },
    { char: 'ä', windows1252: '%E4', utf8: '%C3%A4' },
    { char: 'å', windows1252: '%E5', utf8: '%C3%A5' },
    { char: 'æ', windows1252: '%E6', utf8: '%C3%A6' },
    { char: 'ç', windows1252: '%E7', utf8: '%C3%A7' },
    { char: 'è', windows1252: '%E8', utf8: '%C3%A8' },
    { char: 'é', windows1252: '%E9', utf8: '%C3%A9' },
    { char: 'ê', windows1252: '%EA', utf8: '%C3%AA' },
    { char: 'ë', windows1252: '%EB', utf8: '%C3%AB' },
    { char: 'ì', windows1252: '%EC', utf8: '%C3%AC' },
    { char: 'í', windows1252: '%ED', utf8: '%C3%AD' },
    { char: 'î', windows1252: '%EE', utf8: '%C3%AE' },
    { char: 'ï', windows1252: '%EF', utf8: '%C3%AF' },
    { char: 'ð', windows1252: '%F0', utf8: '%C3%B0' },
    { char: 'ñ', windows1252: '%F1', utf8: '%C3%B1' },
    { char: 'ò', windows1252: '%F2', utf8: '%C3%B2' },
    { char: 'ó', windows1252: '%F3', utf8: '%C3%B3' },
    { char: 'ô', windows1252: '%F4', utf8: '%C3%B4' },
    { char: 'õ', windows1252: '%F5', utf8: '%C3%B5' },
    { char: 'ö', windows1252: '%F6', utf8: '%C3%B6' },
    { char: '÷', windows1252: '%F7', utf8: '%C3%B7' },
    { char: 'ø', windows1252: '%F8', utf8: '%C3%B8' },
    { char: 'ù', windows1252: '%F9', utf8: '%C3%B9' },
    { char: 'ú', windows1252: '%FA', utf8: '%C3%BA' },
    { char: 'û', windows1252: '%FB', utf8: '%C3%BB' },
    { char: 'ü', windows1252: '%FC', utf8: '%C3%BC' },
    { char: 'ý', windows1252: '%FD', utf8: '%C3%BD' },
    { char: 'þ', windows1252: '%FE', utf8: '%C3%BE' },
    { char: 'ÿ', windows1252: '%FF', utf8: '%C3%BF' },
  ]

  decode(url: string): string {

    let urlDecoded: string = url;

    if (urlDecoded != null) {
      this.chars.forEach(char => {
        urlDecoded = urlDecoded.replace(new RegExp(`${char.utf8}`, 'gm'), char.char);
      });
    }

    return urlDecoded;
  }
}

interface UrlDecoderChar {
  char: string;
  windows1252: string;
  utf8: string;
}