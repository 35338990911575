<span class="container__icon-with-mini-range">
  <div class="material-symbols-outlined co2-icon" [style.color]="_miniRangeData?.activeRange?.color"
    [style.font-size]="getZoomedSize(1.5, 'em')">
    eco
  </div>
  <div class="icon-mini-ranges">
    <span class="mini-range" *ngFor="let miniRangeData of _miniRangeData?.ranges ?? []; index as i_mini_range"
      [style.width]="rangeWidth"
      [style.border-color]="_miniRangeData?.activeRangeIndex === i_mini_range? miniRangeData?.color : valueBackground">&nbsp;</span>
  </div>
</span>