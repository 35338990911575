<div *ngIf="_nutritiveItem != null">

  <h2 class="header__view-nutritive">{{ tk.nutrition_table_information }}</h2>

  <table mat-table [dataSource]="getNutritiveTable()" class="table__view-nutritive">
    <ng-container matColumnDef="nutritiveFactor">
      <th mat-header-cell class="table__column-header--align-left" *matHeaderCellDef> {{ tk.nutritive_factor }}
      </th>
      <td mat-cell [style]="row.style" *matCellDef="let row"> {{ row.nutritiveFactor }} </td>
    </ng-container>
    <ng-container matColumnDef="value">
      <th mat-header-cell class="table__column-header--align-right" *matHeaderCellDef> {{
        getNutritiveFactorValueTitle() }} </th>
      <td mat-cell class="table__column--align-right" [style]="row.style" *matCellDef="let row"> {{ row.value }}
      </td>
    </ng-container>
    <ng-container matColumnDef="valuePer100">
      <th mat-header-cell class="table__column-header--align-right" *matHeaderCellDef> {{
        getNutritiveFactorValueTitle(100) }} </th>
      <td mat-cell class="table__column--align-right" [style]="row.style" *matCellDef="let row"> {{ row.valuePer100 }}</td>
    </ng-container>
    <!-- <ng-container matColumnDef="unit">
      <th mat-header-cell class="table__column-header--align-left" *matHeaderCellDef></th>
      <td mat-cell class="table__column--align-left" [style]="row.style" *matCellDef="let row"> {{ row.unit }}</td>
    </ng-container> -->
    <ng-container matColumnDef="nutritionCompareInformation">
      <th mat-header-cell class="table__column-header--align-left" *matHeaderCellDef></th>
      <td mat-cell class="table__column--align-left" [style]="row.style" *matCellDef="let row">
      </td>
    </ng-container>
    <ng-container matColumnDef="compareProportion">
      <th mat-header-cell class="table__column-header--align-right" *matHeaderCellDef>
        {{ tk.reference_intake_short }}
      </th>
      <td mat-cell class="table__column--align-right" [style]="row.style" *matCellDef="let row">
        {{ row.compareProportion }}
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <div *ngIf="+_nutritiveItem?.portionSize > 0" class="container__view-nutritive-ri-info">{{ tk.reference_intake_info }}</div>
</div>