import { Injectable } from '@angular/core';
import { L10nIntlService, L10nTranslationService } from 'angular-l10n';
import { AppConfigService } from 'src/app/services/app-config-service';
import { getAsDate } from 'src/date-functions';
import { TK } from './translation-keys';


export enum FormatType {
  formatBoolean,
  formatText,
  formatDate,
  formatDateTime,
  formatTime,
  formatDecimal0,
  formatDecimal1,
  formatDecimal2,
  formatDecimal3,
  formatCurrency2,
  formatCurrency3,
  formatPercent0,
  formatPercent1,
  formatPercent2,
  formatPercentDivide0,
  formatPercentDivide1,
  formatPercentDivide2
}

/**
 * Service for handling value formatting based on L10nIntlService and
 * simplified format types.
 */
@Injectable({
  providedIn: 'root',
})
export class CustomL10nIntlService {

  private locale: string;

  /**
   * Initialize the service.
   */
  constructor(
    public localeService: L10nIntlService,
    public ts: L10nTranslationService,
    private appConfigService: AppConfigService
  ) {
    this.locale = appConfigService.getConfig().locale;
    // l10nConfig.defaultLocale = { language: appConfig.getConfig().locale, currency: 'EUR' };
  }

  /**
   * Returns formatted string.
   * @param value Value to be formatted.
   * @param formatType Desired formatting.
   */
  format(value: any, formatType: FormatType): string {
    if (value == null) {
      return '';
    }

    switch (formatType) {
      case FormatType.formatText:
        return '' + value;
      case FormatType.formatDate:
        if (this.locale === 'fi') {
          const date: Date = getAsDate(value);
          if (date == null) {
            return '';
          }
          return `${date.getDate()}.${(date.getMonth() + 1)}.${date.getFullYear()}`;
        }
        return this.localeService.formatDate(value, { dateStyle: 'short' }, this.locale);
      case FormatType.formatDateTime:
        if (this.locale === 'fi') {
          const timeString: string = this.localeService.formatDate(value, { timeStyle: 'short' }, this.locale);
          const date: Date = getAsDate(value);
          if (date == null) {
            return '';
          }
          const dateString: string = `${date.getDate()}.${(date.getMonth() + 1)}.${date.getFullYear()}`;
          return `${dateString} ${timeString}`;
        }
        return this.localeService.formatDate(value, { timeStyle: 'short', dateStyle: 'short' }, this.locale);
      case FormatType.formatTime:
        return this.localeService.formatDate(value, { timeStyle: 'short' }, this.locale);
      case FormatType.formatDecimal0:
        return this.localeService.formatNumber(+value, { digits: '1.0-0' }, this.locale);
      case FormatType.formatDecimal1:
        return this.localeService.formatNumber(+value, { digits: '1.1-1' }, this.locale);
      case FormatType.formatDecimal2:
        return this.localeService.formatNumber(+value, { digits: '1.2-2' }, this.locale);
      case FormatType.formatDecimal3:
        return this.localeService.formatNumber(+value, { digits: '1.3-3' }, this.locale);
      case FormatType.formatCurrency2:
        return this.localeService.formatNumber(+value, { digits: '1.2-2', style: 'currency' }, this.locale);
      case FormatType.formatCurrency3:
        return this.localeService.formatNumber(+value, { digits: '1.3-3', style: 'currency' }, this.locale);
      case FormatType.formatPercent0:
        return this.localeService.formatNumber(+value, { digits: '1.0-0', style: 'percent' }, this.locale);
      case FormatType.formatPercent1:
        return this.localeService.formatNumber(+value, { digits: '1.1-1', style: 'percent' }, this.locale);
      case FormatType.formatPercent2:
        return this.localeService.formatNumber(+value, { digits: '1.2-2', style: 'percent' }, this.locale);
      case FormatType.formatPercentDivide0:
        return this.localeService.formatNumber(+value / 100, { digits: '1.0-0', style: 'percent' }, this.locale);
      case FormatType.formatPercentDivide1:
        return this.localeService.formatNumber(+value / 100, { digits: '1.1-1', style: 'percent' }, this.locale);
      case FormatType.formatPercentDivide2:
        return this.localeService.formatNumber(+value / 100, { digits: '1.2-2', style: 'percent' }, this.locale);
      case FormatType.formatBoolean:
        return value === true ? this.ts.translate(TK.yes) : this.ts.translate(TK.no)
      default:
        return '' + value;
    }

    return '';
  }

  /**
   * Returns number as formatted string.
   * @param value Value to be formatted.
   * @param decimals Number of decimals.
   */
  formatNumber(value: any, decimals: number): string {
    if (value == null) {
      return '';
    }
    return this.localeService.formatNumber(+value, { digits: `1.${decimals}-${decimals}` }, this.locale);
  }

}
