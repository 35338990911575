import { OverlayContainer } from '@angular/cdk/overlay';
import { Component, HostBinding, OnDestroy, Renderer2 } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppThemeService } from './services/app-theme.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnDestroy {
  title = 'public-menu';
  private theme: string;
  private subscriptions: Subscription[] = [];

  constructor(
    private renderer: Renderer2,
    private overlayContainer: OverlayContainer,
    public appThemeService: AppThemeService)
  {
    this.subscriptions.push(appThemeService.getThemeChange().subscribe((theme: string) => {
      this.setTheme(theme);
    }));
  }

  @HostBinding('class')
  public get themeMode() {
    return this.appThemeService.getThemeClass(this.theme);
  }

  private setTheme(theme: string): void {
    if (this.theme === theme) {
      return;
    }
    if (!this.appThemeService.isAcceptedTheme(theme)) {
      return;
    }
    this.theme = theme;
    this.renderTheme();
  }

  private renderTheme() {
    this.appThemeService.getThemeBaseClasses().forEach((baseClass: string) => {
      this.renderer.removeClass(document.body, baseClass);
    });
    this.appThemeService.getThemeClasses().forEach((themeClass: string) => {
      this.overlayContainer.getContainerElement().classList.remove(themeClass);
    });
    this.renderer.addClass(document.body, this.appThemeService.getThemeBaseClass(this.theme));
    this.overlayContainer.getContainerElement().classList.add(this.appThemeService.getThemeClass(this.theme));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

}
