import { Component, Input, OnInit } from '@angular/core';
import { MenuPresentationDay, MenuPresentationMode, MenuPresentationOrientation } from '../menu-presentation/menu-presentation.component';
import { CustomL10nIntlService, FormatType } from 'src/data/custom-locale.service';
import { getWeekDayNumber as getWeekDayNumber } from 'src/date-functions';
import { L10nTranslationService } from 'angular-l10n';
import { TK } from 'src/data/translation-keys';
import { getDateAsString } from 'src/date-functions';
import { Co2ValueUI, getCo2ValueForUi, isShowCo2Units } from '../tools/co2-tools';
import { Co2Range, MenuPublishCo2Value } from 'src/data/interfaces';

@Component({
  selector: 'app-menu-presentation-day',
  templateUrl: './menu-presentation-day.component.html',
  styleUrls: ['./menu-presentation-day.component.scss']
})

export class MenuPresentationDayComponent implements OnInit {

  _menuDay!: MenuPresentationDay;
  @Input() set menuDay(menuDay: MenuPresentationDay) {
    this._menuDay = menuDay;
    this.initializeData();
  }

  _language!: string;
  @Input() set language(language: string) {
    this._language = language;
    this.initializeData();
  }

  _mode!: MenuPresentationMode;
  @Input() set mode(mode: MenuPresentationMode) {
    this._mode = mode;
    this.updateCo2();
  }

  _orientation!: MenuPresentationOrientation;
  @Input() set orientation(orientation: MenuPresentationOrientation) {
    this._orientation = orientation;
    this.updateCo2();
  }

  _zoomFactor!: number;
  @Input() set zoomFactor(zoomFactor: number) {
    this._zoomFactor = zoomFactor;
  }

  public showCo2Units: boolean = false;
  public dateFormatted: string;
  public uiMealOptions: UiMealOption[];

  public tk = {
    no_information: ''
  }

  constructor(
    private customLocaleService: CustomL10nIntlService,
    public ts: L10nTranslationService
  ) { }

  private initializeData(): void {

    if (this._language == null || this._menuDay == null) {
      return;
    }

    this.tk.no_information = this.ts.translate(TK.no_information)

    this.dateFormatted = null;
    this.dateFormatted = this._menuDay.date != null ?
      this.customLocaleService.format(this._menuDay.date, FormatType.formatDate) :
      '';

    if (this._menuDay.date != null) {
      const weekDayNameKey: string = `week_day_name_${getWeekDayNumber(new Date(this._menuDay.date))}`;

      const weekDayName: string = this.ts.translate(weekDayNameKey);
      this.dateFormatted = `${weekDayName} ${this.dateFormatted}`;
    }

    this.uiMealOptions = [];

    (this._menuDay.menuPublishDate?.mealOptions ?? []).forEach(uiMealOption => {

      this.uiMealOptions.push({
        name: uiMealOption.names.find(name => name.language === this._language)?.name,
        rows: uiMealOption.rows.map(row => {
          const dietsToSet: string[] = row.diets.find(row => row.language === this._language)?.diets;
          return {
            name: row.names.find(name => name.language === this._language)?.name,
            diets: dietsToSet,
            showDiets: dietsToSet?.length > 0,
            co2Value: getCo2ValueForUi(row.co2Value, this.customLocaleService),
            dietShort: row.diets.find(row => row.language === this._language)?.dietShorts,
            publicInfo: row.publicInfos.find(info => info.language === this._language)?.publicInfo
          }
        })
      })

    });
  }

  updateCo2() {
    this.showCo2Units = isShowCo2Units(this._orientation, this._mode);
  }

  public isCurrentDate(): boolean {
    const now: Date = new Date();
    const currentDate: string = getDateAsString(now);
    return this._menuDay.date === currentDate;
  }

  public getDateFontSize(): string {
    return `calc(1.3em * ${this._zoomFactor})`;
  }

  public getNoDataFontSize(): string {
    return `calc(1.3em * ${this._zoomFactor})`;
  }

  public getMealOptionFontSize(): string {
    return `calc(1.3em * ${this._zoomFactor})`;
  }

  public getNameFontSize(): string {
    return `calc(1.15em * ${this._zoomFactor})`;
  }

  public getDietFontSize(): string {
    return `calc(1em * ${this._zoomFactor})`;
  }

  public getInfoFontSize(): string {
    return `calc(1.15em * ${this._zoomFactor})`;
  }

  public getZoomPx(pixels: number): string {
    return `calc(${pixels}px * ${this._zoomFactor})`;
  }

  public getMealOptionsClass(): string {
    return (this._mode === 'currentDate' && this._orientation === 'landscape') ? 'container__menu-presentation-day-meal-options--flex' :
      'container__menu-presentation-day-meal-options';
  }

  public getMealOptionClass(): string {
    return (this._mode === 'currentDate' && this._orientation === 'landscape') ? 'container__menu-presentation-day-meal-option--flex' :
      'container__menu-presentation-day-meal-option';
  }

  public getMealOptionPadding(): string {
    return (this._mode === 'currentDate' && this._orientation === 'landscape') ? this.getZoomPx(18) : '0';
  }

  ngOnInit(): void {
  }
}

interface UiMealOption {
  name: string;
  rows: UiRow[];
}

interface UiRow {
  name: string;
  diets: string[];
  showDiets: boolean;
  dietShort: string[];
  publicInfo: string;
  co2Value: Co2ValueUI;
}