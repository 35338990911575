import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import { L10nTranslationService } from "angular-l10n";
import { TK } from "src/data/translation-keys";
import { Co2Range } from "src/data/interfaces";

@Component({
  selector: 'app-co2-icon-with-mini-range',
  templateUrl: './view-co2-icon-mini-range.component.html',
  styleUrls: ['./view-co2-icon-mini-range.component.scss']
})
export class ViewCo2IconMiniRangeComponent implements OnDestroy {

  public valueColor: string = '#ffffff';
  public valueBackground: string = '#dfdfdf';

  public rangeWidth: string;


  _miniRangeData: MiniRangeData;
  @Input() set miniRangeData(miniRangeData: MiniRangeData) {
    this._miniRangeData = miniRangeData;
    this.init();
  }

  _zoomFactor!: number;
  @Input() set zoomFactor(zoomFactor: number) {
    this._zoomFactor = zoomFactor;
  }

  constructor(
    public ts: L10nTranslationService,
  ) {

  }

  ngOnDestroy(): void {

  }

  init(): void {
    if (this._miniRangeData == null) {
      return;
    }

    this.rangeWidth = this.getRangeWidth(this._miniRangeData.ranges);

  }

  private getRangeWidth(ranges: Co2Range[]): string {
    if (ranges == null || ranges.length === 0) {
      return '0%';
    }
    return `${(100 / ranges.length).toFixed(1)}%`;
  }

  public getZoomedSize(size: number, unit: string): string {
    // only enabled with zoomFactor, else returns null (not updated by angular)
    if (this._zoomFactor == null) {
      return null;
    }

    return `calc(${size}${unit} * ${this._zoomFactor})`;
  }
}

export interface MiniRangeData {
  activeRange: Co2Range;
  activeRangeIndex: number;
  ranges: Co2Range[];
}