<!-- Screen -->
<div class="container__menu-week-header-view">
  <h1 class="header__menu-week">{{ nameToShow }}</h1>

  <div class="container__menu-week-week-selection">
    <div class="container__menu-week-date-range public-menu-container-accent">{{ dateRangeToShow }}</div>
    <div class="container__menu-week-buttons">
      <button mat-flat-button color="accent" [disabled]="!_isPreviousWeekAvailable" (click)="goToPreviousWeek()">{{
        tk.previous_week }}</button>
      <button mat-flat-button color="accent" [disabled]="!_isNextWeekAvailable" (click)="goToNextWeek()">{{ tk.next_week
        }}</button>
    </div>
    <div class="container__menu-week-expand">
      <button mat-flat-button color="accent" (click)="expandAll()">{{ tk.expand_all_days }}</button>
    </div>
  </div>

  <div *ngIf="infoToShow != null && infoToShow != ''" class="container__menu-week-info">{{ infoToShow }}</div>
</div>
<!-- Print -->
<div class="container__menu-week-header-print">
  <h1 class="header__menu-week--print">{{ nameToShow }}</h1>
  <div class="container__menu-week-date-range--print">{{ dateRangeToShow }}</div>
  <div *ngIf="infoToShow != null && infoToShow != ''" class="container__menu-week-info--print">{{ infoToShow }}</div>
</div>