import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppThemeService {

  private _subjectThemeChange: Subject<string> = new Subject<string>();

  constructor(
  ) {
  }

  public getThemeChange(): Observable<string> {
    return this._subjectThemeChange;
  }

  public isAcceptedTheme(theme: string): boolean {
    return acceptedThemes.map(theme => { return theme.id }).includes(theme);
  }

  public getDefaultTheme(): string {
    return 'light';
  }

  public setTheme(theme: string): void {
    if (this.isAcceptedTheme(theme)) {
      this._subjectThemeChange.next(theme);
    }
  }

  public getThemeClass(theme: string): string {
    if (this.isAcceptedTheme(theme)) {
      return `theme-${theme}`;
    }
    return `theme-${this.getDefaultTheme()}`;
  }

  public getThemeClasses(): string[] {
    return acceptedThemes.map(theme => { return this.getThemeClass(theme.id); });
  }

  public getThemeNames(): string[] {
    return acceptedThemes.map(theme => { return this.getThemeClass(theme.name); });
  }

  public getThemeBaseClass(theme: string): string {
    if (this.isAcceptedTheme(theme)) {
      return theme.startsWith('dark') ? 'theme-dark' : 'theme-light';
    }
    return 'theme-light';
  }

  public getThemeBaseClasses(): string[] {
    return ['theme-light','theme-dark'];
  }
}

export interface MenuTheme {
  id: string;
  name: string;
}

export const acceptedThemes: MenuTheme[] = [
  { id: 'light', name: 'Light (default)' },
  { id: 'dark', name: 'Dark' },
  { id: 'light--red', name: 'Light / Red' },
  { id: 'light--pink', name: 'Light / Pink' },
  { id: 'light--purple', name: 'Light / Purple' },
  { id: 'light--deep-purple', name: 'Light / Deep Purple' },
  { id: 'light--indigo', name: 'Light / Indigo' },
  { id: 'light--blue', name: 'Light / Blue' },
  { id: 'light--light-blue', name: 'Light / Light Blue' },
  { id: 'light--cyan', name: 'Light / Cyan' },
  { id: 'light--teal', name: 'Light / Teal' },
  { id: 'light--green', name: 'Light / Green' },
  { id: 'light--light-green', name: 'Light / Light Green' },
  { id: 'light--lime', name: 'Light / Lime' },
  { id: 'light--yellow', name: 'Light / Yellow' },
  { id: 'light--amber', name: 'Light / Amber' },
  { id: 'light--orange', name: 'Light / Orange' },
  { id: 'light--deep-orange', name: 'Light / Deep Orange' },
  { id: 'light--brown', name: 'Light / Brown' },
  { id: 'light--grey', name: 'Light / Gray' },
  { id: 'light--blue-grey', name: 'Light / Blue Gray' },
  { id: 'dark--red', name: 'Dark / Red' },
  { id: 'dark--pink', name: 'Dark / Pink' },
  { id: 'dark--purple', name: 'Dark / Purple' },
  { id: 'dark--deep-purple', name: 'Dark / Deep Purple' },
  { id: 'dark--indigo', name: 'Dark / Indigo' },
  { id: 'dark--blue', name: 'Dark / Blue' },
  { id: 'dark--light-blue', name: 'Dark / Light Blue' },
  { id: 'dark--cyan', name: 'Dark / Cyan' },
  { id: 'dark--teal', name: 'Dark / Teal' },
  { id: 'dark--green', name: 'Dark / Green' },
  { id: 'dark--light-green', name: 'Dark / Light Green' },
  { id: 'dark--lime', name: 'Dark / Lime' },
  { id: 'dark--yellow', name: 'Dark / Yellow' },
  { id: 'dark--amber', name: 'Dark / Amber' },
  { id: 'dark--orange', name: 'Dark / Orange' },
  { id: 'dark--deep-orange', name: 'Dark / Deep Orange' },
  { id: 'dark--brown', name: 'Dark / Brown' },
  { id: 'dark--grey', name: 'Dark / Gray' },
  { id: 'dark--blue-grey', name: 'Dark / Blue Gray' }
];
