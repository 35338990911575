<div *ngIf="showMainError" class="container__menu-presentation-message public-menu-container-error">
  {{ tk.error_fetching_data }}
</div>
<div *ngIf="showTenantOrSiteNotFound" class="container__menu-presentation-message public-menu-container-warning">
  {{ tk.site_not_found }}
</div>
<div *ngIf="!showTenantOrSiteNotFound && showMenuNotFound"
  class="container__menu-presentation-message public-menu-container-warning">
  {{ tk.menu_not_found }}
</div>
<!-- Screen -->
<div [ngClass]="getPresentationStyle()" [style.max-width]="getPresentationMaxWidthPx()">
  <div class="public-menu-container-border container__menu-presentation-border" [style.padding-top]="getZoomPx(18)"
    [style.padding-right]="getZoomPx(24)" [style.padding-bottom]="getZoomPx(18)" [style.padding-left]="getZoomPx(24)"
    [style.border-radius]="getZoomPx(6)" [style.height]="getHeight(18, 18)">
    <app-menu-presentation-header [mode]="mode" [language]="language" [menuPublishMenu]="menuPublishMenu"
      [menuWeekHeaderDates]="menuWeekHeaderDates" [zoomFactor]="zoomFactor">
    </app-menu-presentation-header>
    <div [ngClass]="getDaysStyle()">
      <app-menu-presentation-day *ngFor="let menuDay of menuDays" [mode]="mode" [orientation]="orientation"
        [menuDay]="menuDay" [language]="language" [zoomFactor]="zoomFactor">
      </app-menu-presentation-day>

      <app-view-co2-legend *ngIf="!showCo2Units" [menuDays]="menuDays" [zoomFactor]=" zoomFactor"></app-view-co2-legend>
    </div>
  </div>
</div>
<!-- Print -->
<div [ngClass]="getPresentationStyle(true)">
  <app-menu-presentation-header [mode]="mode" [language]="language" [menuPublishMenu]="menuPublishMenu"
    [menuWeekHeaderDates]="menuWeekHeaderDates">
  </app-menu-presentation-header>

  <div [ngClass]="getDaysStyle(true)">
    <app-menu-presentation-day *ngFor="let menuDay of menuDays" [mode]="mode" [orientation]="orientation"
      [menuDay]="menuDay" [language]="language">
    </app-menu-presentation-day>

    <app-view-co2-legend *ngIf="!showCo2Units" [menuDays]="menuDays" [zoomFactor]="zoomFactor"></app-view-co2-legend>
  </div>
</div>