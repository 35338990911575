import { MatStartDate } from '@angular/material/datepicker';

/**
 * Returns in format "YYYY-MM-DD".
 *
 * @export
 * @param {(Date | string)} date
 * @return {*}  {string}
 */
export function getDateAsString(date: Date | string): string {
  if (date == null) return null;
  const regexp: RegExp = new RegExp(/^\d{4}-\d{2}-\d{2}$/);
  if (regexp.test(date.toString())) {
    return date as string;
  }

  date = new Date(date);
  const tzoffset: number = (date as Date).getTimezoneOffset() * 60000; //offset in milliseconds
  const localISOTime: string = new Date((date as Date).getTime() - tzoffset).toISOString().slice(0, -1);
  return localISOTime.substring(0, 10);
}

/**
 * Returns string or date object as date object.
 *
 * @export
 * @param {(Date | string)} date The object to be returned as date object.
 * @return {*}  {Date}
 */
export function getAsDate(date: Date | string): Date {
  if (date == null) return null;
  return new Date(date);
}

/**
 * Returns date on another week.
 *
 * @export
 * @param {(Date | string)} date The object to be returned as date on another week
 * @return {*}  {Date}
 */
export function getDateOnAnotherWeek(date: Date | string, weekDifference: number): Date {
  if (date == null) return null;
  var dateToUse: Date = new Date(date);
  var dateOnAnotherWeek: Date = new Date(dateToUse.getFullYear(), dateToUse.getMonth(), dateToUse.getDate() + (7 * weekDifference));
  return dateOnAnotherWeek;
}

/**
 * Returns the monday of given day's week.
 *
 * @export
 * @param {(Date | string)} date The date.
 * @return {*}  {Date}
 */
export function getMondayOfDate(date: Date | string): Date {
  if (date == null) return null;
  const dateToUse: Date = new Date(date);

  const day: number = dateToUse.getDay(),
    diff = dateToUse.getDate() - day + (day == 0 ? -6 : 1);
  return new Date(dateToUse.setDate(diff));
}

export function getFridayOfDate(date: Date | string): Date {
  if (date == null) return null;

  const dateToUse: Date = new Date(date);
  const day: number = dateToUse.getDay();
  const diff: number = dateToUse.getDate() - day + (day == 0 ? -6 : 1);

  return new Date(dateToUse.setDate(diff + 4));
}

export function getSundayOfDate(date: Date | string): Date {
  if (date == null) return null;

  const dateToUse: Date = new Date(date);
  const day: number = dateToUse.getDay();
  // const first = dateToUse.getDate() - (day == 0 ? 6 : day + 1);
  const diff: number = dateToUse.getDate() - day + (day == 0 ? -6 : 1);

  return new Date(dateToUse.setDate(diff + 6));
}

/**
 * Returns dates starting from dateStart (including) until dateEnd (including).
 *
 * @export
 * @param {(Date | string)} dateStart The first date to start.
 * @param {(Date | string)} dateEnd The last date to start.
 * @return {*}  {Date[]}
 */
export function getDatesBetween(dateStart: Date | string, dateEnd: Date | string): Date[] {

  const datesToReturn: Date[] = [];

  const dateStartDate: Date = new Date(dateStart);
  const dateEndDate: Date = new Date(dateEnd);

  datesToReturn.push(dateStartDate);
  // datesToReturn.push(dateEndDate);

  // const currentDate: Date = new Date(dateStartDate.setDate(dateStartDate.getDate()+1));
  let currentDate: Date = new Date(dateStartDate);
  for (let index = 0; index < 100; index++) {
    currentDate = new Date(new Date(currentDate).setDate(currentDate.getDate() + 1));

    if (isDateGreater(currentDate, dateEndDate)) {
      break;
    }

    datesToReturn.push(currentDate);

  }

  return datesToReturn;
}

export function isDateLess(dateFirst: Date | string, dateSecond: Date | string): boolean {
  return isDateLessWithParam(dateFirst, dateSecond, false);
}

export function isDateLessOrSame(dateFirst: Date | string, dateSecond: Date | string): boolean {
  return isDateLessWithParam(dateFirst, dateSecond, true);
}

export function isDateLessThanGivenSecondsOld(date: Date | string, seconds: number): boolean {
  const dateToCheck: Date = new Date(date);
  return new Date().getTime() - dateToCheck.getTime() < seconds * 1000;
}

/**
 * Return the number or date in week.
 * 0 = Sunday
 * ...
 * 6 = Sunday
 *
 * @export
 * @param {Date} date
 * @return {*}  {number}
 */
export function getWeekDayNumber(date: Date): number {
  return date.getDay();
}

/**
 * Return if the first date is greater than the second.
 *
 * @export
 * @param {(Date | string)} dateFirst
 * @param {(Date | string)} dateSecond
 * @return {*}  {boolean}
 */
export function isDateGreater(dateFirst: Date | string, dateSecond: Date | string): boolean {
  return isDateGreaterWithParam(dateFirst, dateSecond, false);
}

export function isDateGreaterOrSame(dateFirst: Date | string, dateSecond: Date | string): boolean {
  return isDateGreaterWithParam(dateFirst, dateSecond, true);
}

function isDateLessWithParam(dateFirst: Date | string, dateSecond: Date | string, equalReturnValue: boolean): boolean {
  const dateFirstDate: Date = new Date(dateFirst);
  const dateSecondDate: Date = new Date(dateSecond);

  const yearFirst: number = dateFirstDate.getUTCFullYear();
  const yearSecond: number = dateSecondDate.getUTCFullYear();

  if (yearFirst > yearSecond) return false;
  if (yearFirst < yearSecond) return true;


  const monthFirst: number = dateFirstDate.getUTCMonth();
  const monthSecond: number = dateSecondDate.getUTCMonth();

  if (monthFirst > monthSecond) return false;
  if (monthFirst < monthSecond) return true;


  const dayFirst: number = dateFirstDate.getUTCDate();
  const daySecond: number = dateSecondDate.getUTCDate();

  if (dayFirst < daySecond) return true;
  if (dayFirst > daySecond) return false;


  // Same year, month and day of the month
  return equalReturnValue;
}

/**
 * Return if the first date is greater than the second.
 *
 * @export
 * @param {(Date | string)} dateFirst
 * @param {(Date | string)} dateSecond
 * @return {*}  {boolean}
 */
function isDateGreaterWithParam(dateFirst: Date | string, dateSecond: Date | string, equalReturnValue: boolean): boolean {

  const dateFirstDate: Date = new Date(dateFirst);
  const dateSecondDate: Date = new Date(dateSecond);

  const yearFirst: number = dateFirstDate.getUTCFullYear();
  const yearSecond: number = dateSecondDate.getUTCFullYear();

  if (yearFirst > yearSecond) return true;
  if (yearFirst < yearSecond) return false;


  const monthFirst: number = dateFirstDate.getUTCMonth();
  const monthSecond: number = dateSecondDate.getUTCMonth();

  if (monthFirst > monthSecond) return true;
  if (monthFirst < monthSecond) return false;


  const dayFirst: number = dateFirstDate.getUTCDate();
  const daySecond: number = dateSecondDate.getUTCDate();

  if (dayFirst > daySecond) return true;
  if (dayFirst < daySecond) return false;


  // Same year, month and day of the month
  return equalReturnValue;

}