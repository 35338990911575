import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MenuViewComponent } from './menu-view/menu-view.component';
import { MenuPresentationComponent } from './menu-presentation/menu-presentation.component';
import { HelpComponent } from './help/help.component';

const routes: Routes = [

  { path: 'fi/week', component: MenuViewComponent },
  { path: 'fi/week/:tenantName/:siteName/:menuName', component: MenuViewComponent },
  { path: 'week/:tenantName/:siteName/:menuName', component: MenuViewComponent },
  { path: 'fi/presentation', component: MenuPresentationComponent },
  { path: 'fi/presentation/:tenantName/:siteName/:menuName', component: MenuPresentationComponent },
  { path: 'presentation/:tenantName/:siteName/:menuName', component: MenuPresentationComponent },
  { path: 'help', component: HelpComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
