<!-- Screen -->
<div class="container__menu-presentation-day-view">
  <div
    [ngClass]="_mode === 'currentDate' ? 'container__menu-presentation-day-header--date' : 'container__menu-presentation-day-header'">
    <div>
      <h2 [style.font-size]="getDateFontSize()" [style.margin-top]="getZoomPx(6)" [style.margin-bottom]="getZoomPx(6)"
        [style.line-height]="getZoomPx(32)">
        {{ dateFormatted }}
      </h2>
    </div>
    <div *ngIf="isCurrentDate() && _mode === 'currentWeek'" class="container__dot public-menu-container-highlight"
      [style.width]="getZoomPx(8)" [style.height]="getZoomPx(8)" [style.min-width]="getZoomPx(8)"
      [style.margin-left]="getZoomPx(4)"></div>
  </div>

  <div *ngIf="_menuDay.noData == true" class="container__menu-presentation-day-no-data"
    [style.font-size]="getNoDataFontSize()" [style.margin-top]="getZoomPx(6)" [style.margin-bottom]="getZoomPx(8)">
    {{ tk.no_information }}
  </div>

  <div [ngClass]="getMealOptionsClass()">
    <div [ngClass]="getMealOptionClass()" [style.padding-left]="getMealOptionPadding()"
      [style.padding-right]="getMealOptionPadding()" *ngFor="let mealOption of uiMealOptions">
      <h3 class="header__menu-presentation-day-meal-option" [style.font-size]="getMealOptionFontSize()">{{
        mealOption.name }}</h3>
      <ng-container *ngFor="let row of mealOption.rows">
        <div class="container__menu-presentation-day-row" [style.padding-top]="getZoomPx(6)"
          [style.padding-bottom]="getZoomPx(6)">
          <div class="container__menu-presentation-day-row-name" [style.font-size]="getNameFontSize()"
            [style.margin-top]="getZoomPx(3)" [style.margin-bottom]="getZoomPx(3)" [style.margin-right]="getZoomPx(4)">
            {{ row.name }}</div>
          <div *ngIf="row.dietShort != null" class="container__menu-presentation-day-diets">
            <div *ngFor="let dietShort of row.dietShort"
              class="container__menu-presentation-day-diet public-menu-container-accent"
              [style.font-size]="getDietFontSize()" [style.padding-top]="getZoomPx(4)"
              [style.padding-right]="getZoomPx(8)" [style.padding-bottom]="getZoomPx(4)"
              [style.padding-left]="getZoomPx(8)" [style.margin-top]="getZoomPx(4)" [style.margin-right]="getZoomPx(4)"
              [style.margin-bottom]="getZoomPx(4)" [style.border-radius]="getZoomPx(4)">
              {{ dietShort }}
            </div>
          </div>
          <app-view-co2-value-row [style.width]="showCo2Units ? getZoomPx(140) : getZoomPx(40)" [value]="row.co2Value"
            [showUnitAndValue]="showCo2Units" [zoomFactor]="_zoomFactor"></app-view-co2-value-row>
        </div>
        <div *ngIf="row.publicInfo != null && row.publicInfo != ''" class="container__menu-presentation-day-info"
          [style.font-size]="getInfoFontSize()" [style.padding-bottom]="getZoomPx(12)">{{ row.publicInfo }}
        </div>
        <mat-divider></mat-divider>
      </ng-container>
      <!-- Spacer -->
      <div [style.height]="getZoomPx(16)"></div>
    </div>
  </div>
</div>

<!-- Print -->
<div class="container__menu-presentation-day-print">
  <h2 *ngIf="_menuDay.noData != true" class="header__menu-presentation-day-date--print">{{ dateFormatted }}</h2>

  <ng-container *ngFor="let mealOption of uiMealOptions">
    <h3 class="header__menu-presentation-day-meal-option--print">{{ mealOption.name }}</h3>
    <ng-container *ngFor="let row of mealOption.rows">
      <div class="container__menu-presentation-day-row--print">
        <div class="container__menu-presentation-day-row-name--print">{{ row.name }}</div>
        <div *ngIf="row.dietShort != null" class="container__menu-presentation-day-diets--print">
          <div *ngFor="let dietShort of row.dietShort" class="container__menu-presentation-day-diet--print">
            {{ dietShort }}
          </div>
        </div>

        <app-view-co2-value-row [value]="row.co2Value"
          [showUnitAndValue]="_mode === 'currentDate' || _orientation === 'portrait'? true : false"
          [zoomFactor]="_zoomFactor"></app-view-co2-value-row>

      </div>
      <div *ngIf="row.publicInfo != null && row.publicInfo != ''" class="container__menu-presentation-day-info--print">
        {{ row.publicInfo }}</div>
      <hr />
    </ng-container>
  </ng-container>
</div>