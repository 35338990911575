import { CustomL10nIntlService } from "src/data/custom-locale.service";
import { Co2Range, MenuPublishCo2Value } from "src/data/interfaces";
import { MenuPresentationMode, MenuPresentationOrientation } from "../menu-presentation/menu-presentation.component";

export function isCo2ValueWithinRange(value: number, range: Co2Range, isLast: boolean): boolean {
    // 0 would mean that there is no calculated value
    if (value == 0) {
        return false;
    }

    return value >= range.min && (value < range.max || isLast === true);
}

export function getCo2ValueForUi(co2Value: MenuPublishCo2Value, customLocaleService: CustomL10nIntlService): Co2ValueUI {
    if (co2Value == null) {
        return null;
    }

    const valueColor: string = co2Value.ranges?.find((range: Co2Range, index: number) =>
        isCo2ValueWithinRange(+co2Value.valuePerPortionInKg, range, index + 1 === co2Value.ranges.length) === true)?.color;

    const valueFormatted: string = getCo2ValueFormatted(co2Value.valuePerPortionInKg, customLocaleService);

    return {
        ...co2Value,
        valueFormatted,
        valueColorHex: valueColor
    };
}

export function getCo2ValueFormatted(value: string, customLocaleService: CustomL10nIntlService): string {
    if (+value === 0) {
        return undefined;  // instead of '-,--' show nothing
    }

    if (+value <= 0.004) {
        return '< ' + customLocaleService.formatNumber('0.01', 2);
    }

    return customLocaleService.formatNumber(value, 2);
}

export function getCo2RangeValueFormatted(value: string | number, customLocaleService: CustomL10nIntlService): string {
    const valueToFormat: number = +`${value}`;

    if (valueToFormat === 0) {
        return '0';
    }

    if (valueToFormat < 1) {
        return customLocaleService.formatNumber(valueToFormat, 1);
    }

    return `${valueToFormat}`;
}

export function isShowCo2Units(orientation: MenuPresentationOrientation, mode: MenuPresentationMode) {
    return (orientation === 'portrait' && mode === 'currentWeek') || (orientation === 'portrait' && mode === 'currentDate');
}

export interface Co2ValueUI extends MenuPublishCo2Value {
    valueFormatted: string;
    valueColorHex: string;
}
