<div class="container-main">
  <span class="top-cursor material-symbols-outlined" [style.left]="cursorCalculatedLocation"
    [style.color]="cursorWithinRange=== true ? cursorColor : '#ffffff00'">
    {{cursorCalculatedLocation != null ? 'navigation' : '&nbsp;'}}
  </span>
  <div class="container-ranges">
    <div class="container-range" *ngFor="let range of ranges">

      <div class="bottom-range--bottom hover"
        [ngClass]="{'first-range-bottom': range.first, 'last-range-bottom': range.last, 'disabled-range': !range.withinRange}"
        [style.border-top-color]="range.color" [style.background]="range.colorVeryLight"
        [matTooltip]="range.minFormatted + ' ≤ CO2 \< ' + range.maxFormatted">
        {{range.name}}
      </div>

    </div>
  </div>
</div>