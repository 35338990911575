import { Component, Input } from '@angular/core';
import { L10nTranslationService } from 'angular-l10n';
import { CustomL10nIntlService, FormatType } from 'src/data/custom-locale.service';
import { MenuPublishNutritiveItem, MenuPublishNutritiveValue } from 'src/data/interfaces';
import { getFromGeneralNames } from 'src/data/tools';
import { TK } from 'src/data/translation-keys';

@Component({
  selector: 'app-view-nutritive-table',
  templateUrl: './view-nutritive-table.component.html',
  styleUrls: ['./view-nutritive-table.component.scss']
})
export class ViewNutritiveTableComponent {

  protected basicInitialized: boolean = false;

  public _nutritiveItem: MenuPublishNutritiveItem;
  public _language: string;

  @Input() set language(languageId: string) {
    this._language = languageId;
    if (this._nutritiveItem != null) {
      this.init();
    }
  }

  @Input() set nutritiveItem(nutritiveItem: MenuPublishNutritiveItem) {
    this._nutritiveItem = nutritiveItem;
    if (this._language != null) {
      this.init();
    }
  }

  public nutritiveTable: NutritiveInformationFactor[] = [];
  public displayedColumns: string[] = [];

  tk = {
    nutritive_factor_value: '',
    nutritive_factor: '',
    reference_intake_short: '',
    nutrition_table_information: '',
    reference_intake_info: ''
  };

  constructor(
    public ts: L10nTranslationService,
    public customLocaleService: CustomL10nIntlService,
  ) {
  }

  private initTranslations(): void {
    this.tk.nutritive_factor_value = this.ts.translate(TK.nutritive_factor_value);
    this.tk.nutritive_factor = this.ts.translate(TK.nutritive_factor);
    this.tk.reference_intake_short = this.ts.translate(TK.reference_intake_short);
    this.tk.nutrition_table_information = this.ts.translate(TK.nutrition_table_information);
    this.tk.reference_intake_info = this.ts.translate(TK.reference_intake_info);
  }

  /**
   * Initialize the component. Init default values and fetch entities from the service.
   *
   * @memberof ViewNutritiveTableQuestionComponent
   */
  public init(): void {

    this.initTranslations();

    this.initBasic();

    this.initNutritiveTable();
  }

  /**
   * Init basic values.
   *
   * @memberof ViewNutritiveTableQuestionComponent
   */
  public initBasic(): void {
    if (this.basicInitialized) {
      return;
    }

    this.displayedColumns = ['nutritiveFactor'];
    if (+this._nutritiveItem?.portionSize > 0) {
      this.displayedColumns.push('value');
    }
    this.displayedColumns.push('valuePer100');
    if (+this._nutritiveItem?.portionSize > 0) {
      this.displayedColumns.push('nutritionCompareInformation');
      this.displayedColumns.push('compareProportion');
    }

    this.basicInitialized = true;
  }

  /**
   * Init nutritive table.
   *
   * @memberof ViewNutritiveTableQuestionComponent
   */
  public async initNutritiveTable(): Promise<void> {
    const setFactorGroups: Set<string> = new Set<string>();
    const addedNutritiveFactorIds: Set<string> = new Set<string>();
    for (const factor of this._nutritiveItem?.factors ?? []) {
      if (factor.mainGroupCode !== 'MACROCMP') {
        continue;
      }

      setFactorGroups.add(factor.mainGroupCode);

      const value: MenuPublishNutritiveValue = factor.values?.find(value => value.language === this._language);
      if (value == null) {
        continue;
      }

      this.nutritiveTable.push(
        {
          nutritiveFactor: getFromGeneralNames(factor.nutritiveFactorNames, this._language),
          value: value.value,
          valuePer100: value.value100,
          compareProportion: this.customLocaleService.format(factor.compareProportion, FormatType.formatDecimal1),
        }
      );
    }
  }

  /**
   * Returns data of nutritive group
   *
   * @param nutritiveGroup Group code.
   * @memberof ViewNutritiveTableQuestionComponent
   */
  public getNutritiveTable(): NutritiveInformationFactor[] {
    return this.nutritiveTable;
  }

  /**
   * Returns title for portion size column
   *
   * @param portionSize Portion size.
   * @memberof ViewNutritiveTableQuestionComponent
   */
  public getNutritiveFactorValueTitle(portionSize?: number): string {
    return `${this.tk.nutritive_factor_value}/${this.customLocaleService.
      format(portionSize != null ? portionSize : this._nutritiveItem?.portionSize, FormatType.formatDecimal0) + ' ' +
      getFromGeneralNames(this._nutritiveItem?.portionUnitOfMeasurementNames, this._language)}`
  }

}

export interface NutritiveInformationFactor {
  nutritiveFactor: string;
  value: string;
  valuePer100?: string;
  compareProportion?: string;
}

export interface UiNutritiveFactor {
  nutritiveFactorName: string;
  value: string;
  valuePer100?: string;
  unitOfMeasurementName: string;
}