import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import { L10nTranslationService } from "angular-l10n";
import { TK } from "src/data/translation-keys";
import { Co2Range } from "src/data/interfaces";
import { MenuPresentationDay } from "src/app/menu-presentation/menu-presentation.component";

@Component({
  selector: 'app-view-co2-legend',
  templateUrl: './view-co2-legend.component.html',
  styleUrls: ['./view-co2-legend.component.scss']
})
export class ViewCo2LegendComponent implements OnDestroy {

  public valueColor: string = '#ffffff';

  _ranges: Co2Range[];
  @Input() set ranges(ranges: Co2Range[]) {
    this.init(ranges);
  }

  @Input() set menuDays(menuDays: MenuPresentationDay[]) {
    if (menuDays == null || this._ranges != null) {
      return;
    }

    let ranges: Co2Range[];

    for (let menuDay of menuDays) {
      if (ranges != null) { break; }
      for (let mealOption of menuDay?.menuPublishDate?.mealOptions) {
        if (ranges != null) { break; }
        for (let row of mealOption.rows) {
          if (row.co2Value?.ranges?.length > 0) {
            ranges = row.co2Value.ranges;
            break;
          }
        }
      }
    }

    if (ranges?.length > 0) {
      this.init(ranges);
    }
  }

  _zoomFactor!: number;
  @Input() set zoomFactor(zoomFactor: number) {
    this._zoomFactor = zoomFactor;
  }

  _bottomHeight: string;
  @Input() set bottomHeight(bottomHeight: string) {
    this._bottomHeight = bottomHeight;
  }

  constructor(
    public ts: L10nTranslationService,
  ) {
    this.tk.carbon_footprint = ts.translate(TK.carbon_footprint);
    this.tk.very_low = ts.translate(TK.very_low);
    this.tk.low = ts.translate(TK.low);
    this.tk.medium = ts.translate(TK.medium);
    this.tk.high = ts.translate(TK.high);
    this.tk.very_high = ts.translate(TK.very_high);
    this.tk.co2_unit = ts.translate(TK.co2_unit);
    this.tk.co2_unit_short = ts.translate(TK.co2_unit_short);
  }

  public tk = {
    carbon_footprint: '',
    very_low: '',
    low: '',
    medium: '',
    high: '',
    very_high: '',
    co2_unit: '',
    co2_unit_short: '',
  };

  ngOnDestroy(): void {

  }

  init(ranges: Co2Range[]): void {
    if (ranges == null || ranges.length === 0) {
      return;
    }

    ranges.forEach(range => range.name = this.ts.translate(range.nameKey));
    this._ranges = JSON.parse(JSON.stringify(ranges));
  }

  public getZoomedSize(size: number, unit: string): string {
    // only enabled with zoomFactor, else returns null (not updated by angular)
    if (this._zoomFactor == null) {
      return null;
    }

    return `calc(${size}${unit} * ${this._zoomFactor})`;
  }
}