import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-menu-language-bar',
  templateUrl: './menu-language-bar.component.html',
  styleUrls: ['./menu-language-bar.component.scss']
})
export class MenuLanguageBarComponent implements OnInit {

  constructor() { }

  _selectedLanguage!: string;
  @Input() set language(language: string) {
    this._selectedLanguage = language;
  }

  _languages: SingleLanguage[] = [];
  @Input() set languages(languages: SingleLanguage[]) {
    this._languages = languages ?? [];
  }

  @Output() languageSelected: EventEmitter<string> = new EventEmitter();

  ngOnInit(): void {
  }

  selectLanguage(languageCode: string): void {
    this.languageSelected.emit(languageCode);
  }
}

export interface SingleLanguage {
  name: string;
  code: string;
}

