import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, of, Subscription } from 'rxjs';
import { GeneralServiceError } from './general-service-error';

/**
 * Service to load and provide app configs.
 *
 * @export
 * @class AppConfigService
 */
@Injectable({
  providedIn: 'root'
})
export class AppConfigService implements OnDestroy {
  private appConfig!: AppConfig;
  private subscriptions: Subscription[] = [];
  private cacheTimestamp!: number;
  private cacheLimit: number = 900 * 1000;

  constructor(
    private http: HttpClient,
  ) {
  }

  async loadAppConfig(): Promise<void> {
    return this.http.get('/assets/appConfig.json')
      .toPromise()
      .then(data => {
        this.appConfig = data as AppConfig;
      });
  }

  getBackEndUrl(): string {
    return `${this.appConfig.backendUrl}:${this.appConfig.backendPort}`;
  }

  getConfig(): AppConfig {
    return this.appConfig;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}

/**
 * The interface of app configs.
 */
export interface AppConfig {
  backendUrl: string;
  backendPort: string;
  locale: string;
}

function errorHandler(error: HttpErrorResponse): Observable<GeneralServiceError> {
  if (error.error instanceof ErrorEvent) {
    // A client-side or network error occurred. Handle it accordingly.
    console.error('An error occurred:', error.error.message);
  } else {
    // The backend returned an unsuccessful response code.
    // The response body may contain clues as to what went wrong,
    console.error(
      `Backend returned code ${error.status}, ` +
      `body was: ${JSON.stringify(error.error)}`);
  }
  // return an observable with a user-facing error message
  return of({
    successful: false,
    status: error.status,
    noRight: error.status === 403
  } as GeneralServiceError);
}