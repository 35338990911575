import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import { L10nTranslationService } from "angular-l10n";
import { TK } from "src/data/translation-keys";
import { Co2Range } from "src/data/interfaces";
import { Co2ValueUI, getCo2RangeValueFormatted, isCo2ValueWithinRange } from "src/app/tools/co2-tools";
import { CustomL10nIntlService } from "src/data/custom-locale.service";

@Component({
  selector: 'app-view-co2-value-row',
  templateUrl: './view-co2-value-row.component.html',
  styleUrls: ['./view-co2-value-row.component.scss']
})
export class ViewCo2ValueRowComponent implements OnDestroy {

  public rangeWithin: Co2Range;
  public currentRangeIndex: number;

  public valueColor: string = '#ffffff';
  public tooltipText: string;

  co2Value: Co2ValueUI;
  @Input() set value(value: Co2ValueUI) {
    this.init(value);
  }

  isShowUnitAndValue: boolean = false;
  @Input() set showUnitAndValue(showUnitAndValue: boolean) {
    this.isShowUnitAndValue = showUnitAndValue;
  }

  isShowUnit: boolean = true;
  @Input() set showUnit(showUnit: boolean) {
    this.isShowUnit = showUnit;
  }

  _zoomFactor!: number;
  @Input() set zoomFactor(zoomFactor: number) {
    this._zoomFactor = zoomFactor;
  }

  constructor(
    public customLocaleService: CustomL10nIntlService,
    private ts: L10nTranslationService,
  ) {
    this.tk.carbon_footprint = ts.translate(TK.carbon_footprint);
    this.tk.very_low = ts.translate(TK.very_low);
    this.tk.low = ts.translate(TK.low);
    this.tk.medium = ts.translate(TK.medium);
    this.tk.high = ts.translate(TK.high);
    this.tk.very_high = ts.translate(TK.very_high);
    this.tk.co2_unit = ts.translate(TK.co2_unit);
    this.tk.co2_unit_short = ts.translate(TK.co2_unit_short);
  }

  public tk = {
    carbon_footprint: '',
    very_low: '',
    low: '',
    medium: '',
    high: '',
    very_high: '',
    co2_unit: '',
    co2_unit_short: '',
  };

  ngOnDestroy(): void {

  }

  public init(co2Value?: Co2ValueUI): void {
    if (!this.canInit(co2Value)) {
      return;
    }
    this.co2Value = co2Value;

    this.initContent();
  }

  canInit(co2Value: Co2ValueUI): boolean {
    return co2Value != null && co2Value.valueFormatted != null;
  }

  public getZoomedSize(size: number, unit: string): string {
    // only enabled with zoomFactor, else returns null (not updated by angular)
    if (this._zoomFactor == null) {
      return null;
    }

    return `calc(${size}${unit} * ${this._zoomFactor})`;
  }

  private async initContent(): Promise<void> {
    const co2ValueNum: number = +this.co2Value.valuePerPortionInKg;

    for (let i = 0; i < this.co2Value.ranges.length; i++) {
      if (isCo2ValueWithinRange(co2ValueNum, this.co2Value.ranges[i], i + 1 === this.co2Value.ranges.length) === true) {
        this.rangeWithin = this.co2Value.ranges[i];
        this.currentRangeIndex = i;
        break;
      }
    }

    if (this.rangeWithin == null) {
      return;
    }

    this.rangeWithin.name = this.ts.translate(this.rangeWithin.nameKey);

    this.tooltipText =
      `${this.tk.carbon_footprint}:
      ${this.co2Value.valueFormatted} ${this.tk.co2_unit}

      ${this.rangeWithin.name} (${getCo2RangeValueFormatted(this.rangeWithin.min, this.customLocaleService)}-${getCo2RangeValueFormatted(this.rangeWithin.max, this.customLocaleService)} ${this.tk.co2_unit_short})`
  }
}