import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { L10nTranslationService } from 'angular-l10n';
import { CustomL10nIntlService, FormatType } from 'src/data/custom-locale.service';
import { MenuPublishMenu } from 'src/data/interfaces';
import { TK } from 'src/data/translation-keys';
import { MenuViewService } from '../menu-view/menu-view.service';

@Component({
  selector: 'app-menu-week-header',
  templateUrl: './menu-week-header.component.html',
  styleUrls: ['./menu-week-header.component.scss']
})
export class MenuWeekHeaderComponent implements OnInit {

  @Output() moveToNextWeek: EventEmitter<string> = new EventEmitter();
  @Output() moveToPreviousWeek: EventEmitter<string> = new EventEmitter();
  @Output() expandAllDays: EventEmitter<string> = new EventEmitter();

  _isPreviousWeekAvailable: boolean;
  @Input() set isPreviousWeekAvailable(value: boolean) {
    this._isPreviousWeekAvailable = value;
  }

  _isNextWeekAvailable: boolean;
  @Input() set isNextWeekAvailable(value: boolean) {
    this._isNextWeekAvailable = value;
  }

  _menuPublishMenu!: MenuPublishMenu;
  @Input() set menuPublishMenu(menuPublishMenu: MenuPublishMenu) {
    this._menuPublishMenu = menuPublishMenu;
    this.initializeMenuNames();
  }

  _menuWeekHeaderDates!: MenuWeekHeaderDates;
  @Input() set menuWeekHeaderDates(menuWeekHeaderDates: MenuWeekHeaderDates) {
    this._menuWeekHeaderDates = menuWeekHeaderDates;
    this.initializeMenuDates();
  }

  _language!: string;
  @Input() set language(language: string) {
    this._language = language;
    this.initializeMenuNames();
    this.initializeMenuDates();
  }

  _service!: MenuViewService;
  @Input() set service(service: MenuViewService) {
    this._service = service;
  }

  public nameToShow!: string;
  public infoToShow!: string;
  public dateRangeToShow!: string;

  public tk = {
    previous_week: '',
    next_week: '',
    expand_all_days: ''
  };

  constructor(
    private customLocaleService: CustomL10nIntlService,
    private ts: L10nTranslationService
  ) { }

  ngOnInit(): void {
  }

  goToPreviousWeek(): void {
    this.moveToPreviousWeek.emit();
  }

  goToNextWeek(): void {
    this.moveToNextWeek.emit();
  }

  expandAll(): void {
    this.expandAllDays.emit();
  }

  private initializeMenuNames(): void {

    if (this._menuPublishMenu == null || this._language == null) {
      return;
    }

    this.tk.previous_week = this.ts.translate(TK.previous_week);
    this.tk.next_week = this.ts.translate(TK.next_week);
    this.tk.expand_all_days = this.ts.translate(TK.expand_all_days);

    this.nameToShow = (this._menuPublishMenu.menuNames ?? []).find(name => name.language === this._language)?.name ?? '';
    this.infoToShow = (this._menuPublishMenu.menuInformation ?? []).find(info => info.language === this._language)?.information ?? '';
  }

  private initializeMenuDates(): void {

    if (this._menuWeekHeaderDates == null || this._language == null) {
      return;
    }

    const startDateFormatted: string = this._menuWeekHeaderDates.startDate != null ?
      this.customLocaleService.format(this._menuWeekHeaderDates.startDate, FormatType.formatDate) :
      '';

    const endDateFormatted: string = this._menuWeekHeaderDates.endDate != null ?
      this.customLocaleService.format(this._menuWeekHeaderDates.endDate, FormatType.formatDate) :
      '';


    this.dateRangeToShow = `${startDateFormatted} - ${endDateFormatted}`;
  }
}

export interface MenuWeekHeaderDates {
  startDate: string;
  endDate: string;
}