import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { L10nTranslationService } from 'angular-l10n';
import { Subscription } from 'rxjs';
import { CustomL10nIntlService } from 'src/data/custom-locale.service';
import { Co2Range, MenuPublishAvailableMenu, MenuPublishDate, MenuPublishDateDataReport, MenuPublishMenu } from 'src/data/interfaces';
import { TK } from 'src/data/translation-keys';
import { getDateAsString, getDatesBetween, getFridayOfDate, getMondayOfDate, getSundayOfDate } from 'src/date-functions';
import { SingleLanguage } from '../menu-language-bar/menu-language-bar.component';
import { MenuWeekHeaderDates } from '../menu-week-header/menu-week-header.component';
import { AppThemeService } from '../services/app-theme.service';
import { GeneralServiceError } from '../services/general-service-error';
import { MenuService } from '../services/menu.service';
import { isShowCo2Units } from '../tools/co2-tools';

@Component({
  selector: 'app-menu-presentation',
  templateUrl: './menu-presentation.component.html',
  styleUrls: ['./menu-presentation.component.scss']
})
export class MenuPresentationComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = [];
  public tenantName!: string;
  public siteName!: string;
  public menuName!: string;
  public mode!: MenuPresentationMode;
  public weekend!: boolean;
  public orientation!: MenuPresentationOrientation;
  public language!: string;
  public theme!: string;
  public zoomFactor!: number;
  public menuWeekHeaderDates!: MenuWeekHeaderDates;
  public menuPublishMenu!: MenuPublishMenu;
  public currentLanguages: SingleLanguage[];
  public showCo2Units: boolean = false;

  public currentDate!: string;
  public currentWeekStartDate!: string;
  public currentWeekEndDate!: string;

  public showMainError = false;
  public showTenantOrSiteNotFound = false;
  public showMenuNotFound = false;

  public menuDays: MenuPresentationDay[];
  public availableMenus: MenuPublishAvailableMenu[];

  private allowedLanguageCodesInOrder: string[] = ['fi', 'sv', 'en'];

  tk = {
    error_fetching_data: '',
    menu_not_found: '',
    site_not_found: ''
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private route: ActivatedRoute,
    private menuService: MenuService,
    public appThemeService: AppThemeService,
    public ts: L10nTranslationService,
    public customLocaleService: CustomL10nIntlService,
  ) {
    this.subscriptions.push(route.params.subscribe(val => {
      this.init();
    }));

  }

  ngOnInit(): void {
  }

  private init(): void {
    // Url change
    this.subscriptions.push(this.activatedRoute
      .queryParams
      .subscribe(async params => {

        this.tenantName = this.route.snapshot.paramMap.get('tenantName') as string;
        this.siteName = this.route.snapshot.paramMap.get('siteName') as string;
        this.menuName = this.route.snapshot.paramMap.get('menuName') as string;
        this.mode = params['mode'] as MenuPresentationMode ?? 'currentDate';
        this.weekend = ((params['weekend'] as string) ?? 'true') === 'true';
        this.orientation = params['orientation'] as MenuPresentationOrientation ?? 'portrait';
        this.theme = params['theme'] as string ?? this.appThemeService.getDefaultTheme();
        this.zoomFactor = this.getZoomFactor(+params['zoom']);
        this.currentDate = getDateAsString(new Date());
        this.updateCo2();
        const languageFromParameter: string = params['lang'];

        let languageToUse: string;
        if (this.allowedLanguageCodesInOrder.includes(languageFromParameter)) {
          languageToUse = languageFromParameter;
        } else {
          languageToUse = 'fi';
        }
        await this.setLanguage(languageToUse)
        document.documentElement.lang = languageToUse;
        this.initializeTranslations();

        this.currentWeekStartDate = getDateAsString(getMondayOfDate(this.currentDate));
        this.currentWeekEndDate = this.weekend === true ? getDateAsString(getSundayOfDate(this.currentDate)) : getDateAsString(getFridayOfDate(this.currentDate));

        this.menuWeekHeaderDates = this.mode === 'currentWeek' ? { startDate: this.currentWeekStartDate, endDate: this.currentWeekEndDate } :
          { startDate: this.currentDate, endDate: this.currentDate };

        this.appThemeService.setTheme(this.theme);

        try {
          this.initializeDateData();
        } catch (error) {
          console.error(`Error when initializing date data`);
        }

        try {
          this.updateMenuInformation();
        } catch (error) {
          console.error(`Error when initializing menus`);
        }
      }));
  }

  private async setLanguage(code: string): Promise<void> {
    await this.ts.setLocale({ language: code });
    this.language = code;
  }

  initializeTranslations(): void {
    this.tk.error_fetching_data = this.ts.translate(TK.error_fetching_data);
    this.tk.menu_not_found = this.ts.translate(TK.menu_not_found);
    this.tk.site_not_found = this.ts.translate(TK.site_not_found);
  }

  initializeDateData(): void {

    let daysToFetch: string[] = [];
    if (this.mode === 'currentWeek') {
      daysToFetch = getDatesBetween(this.currentWeekStartDate, this.currentWeekEndDate).map(day => getDateAsString(day));
    } else {
      daysToFetch.push(this.currentDate);
    }

    this.subscriptions.push(
      this.menuService
        .getDateData(this.tenantName, this.siteName, this.menuName, daysToFetch)
        .subscribe((dates: MenuPublishDateDataReport[]) => {
          this.menuDays = [];
          dates.forEach(date => {
            this.menuDays.push({
              date: getDateAsString(date?.date),
              noData: date.data?.mealOptions == null || date.data?.mealOptions.length < 1,
              menuPublishDate: date.data
            });
          });
          this.showMainError = false;
        }, error => {
          this.showMainError = true;
        }),
    );

    this.subscriptions.push(
      this.menuService
        .getMenuData(this.tenantName, this.siteName, this.menuName)
        .subscribe((menuPublishMenu: MenuPublishMenu) => {
          this.menuPublishMenu = menuPublishMenu;
          const languageCodes: string[] = (this.menuPublishMenu?.menuInformation?.map(information => information.language) ?? [])
            .sort((lang1, lang2) => this.allowedLanguageCodesInOrder.indexOf(lang1) - this.allowedLanguageCodesInOrder.indexOf(lang2));
          this.currentLanguages = languageCodes
            .map(languageCode => { return { name: this.ts.translate(`language_${languageCode}`), code: languageCode } });
          if (!languageCodes.includes(this.language)) {
            this.setLanguage(this.currentLanguages[0].code ?? 'fi')
          }
          this.showMenuNotFound = false;
        }, error => {
          this.showMenuNotFound = true;
        })
    );
  }

  private updateMenuInformation(): void {
    this.subscriptions.push(
      this.menuService
        .getMenus(this.tenantName, this.siteName)
        .subscribe((availableMenus: MenuPublishAvailableMenu[]) => {
          this.availableMenus = availableMenus;
          this.showMainError = false;
          this.showTenantOrSiteNotFound = false;
        }, ((error: GeneralServiceError) => {
          this.showTenantOrSiteNotFound = true;
        }))
    );
  }

  private getZoomFactor(zoomPercentage: number): number {
    if (zoomPercentage < 30 || zoomPercentage > 400 || zoomPercentage == null || Number.isNaN(zoomPercentage)) {
      return 1;
    }
    return Math.round(zoomPercentage) / 100;
  }

  public getPresentationStyle(print: boolean = false): string {
    const baseStyle: string = print === true ? `container__menu-presentation-print` : `container__menu-presentation`;
    return this.orientation === 'landscape' ? `${baseStyle}--landscape` : `${baseStyle}--portrait`;
  }

  public getPresentationMaxWidthPx(): string {
    return this.orientation === 'portrait' ? `calc(800 * ${this.zoomFactor}px` : 'unset';
  }

  updateCo2() {
    this.showCo2Units = isShowCo2Units(this.orientation, this.mode);
  }

  public getDaysStyle(print: boolean = false): string {
    const baseStyle: string = print === true ? `container__menu-presentation-days-print` : `container__menu-presentation-days`;

    if (this.mode === 'currentDate' && this.orientation === 'landscape') {
      return `${baseStyle}--landscape--flex`;
    }

    if (this.mode === 'currentWeek' && this.orientation === 'landscape') {
      return `${baseStyle}--landscape${this.weekend === true ? '--weekend' : ''}`;
    }

    return `${baseStyle}--portrait${this.weekend === true ? '--weekend' : ''}`;
  }

  public getZoomPx(pixels: number): string {
    return `calc(${pixels}px * ${this.zoomFactor})`;
  }

  public getHeight(paddingTop: number, paddingBottom: number) {
    return `calc(100% - (${paddingTop}px * ${this.zoomFactor}) - (${paddingBottom}px * ${this.zoomFactor}))`;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}

export interface MenuPresentationDay {
  date: string;
  noData?: boolean;
  menuPublishDate: MenuPublishDate;
}

export type MenuPresentationOrientation = 'portrait' | 'landscape';
export type MenuPresentationMode = 'currentDate' | 'currentWeek';
