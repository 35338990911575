<div class="container__main">

  <div class="container__value-and-icon">
    <div class="container__main-value">
      <div class="main-value">{{co2Value?.valueFormatted}}</div>
      <div class="main-value-unit" *ngIf="co2Value != null">
        <span class="initial-ratio">{{tk.co2_unit}}</span>
      </div>
    </div>
    <div class="material-symbols-outlined co2-icon" [style.color]="co2Value?.valueColorHex">
      eco
    </div>
  </div>

  <app-co2-range-view [co2Ranges]="co2Value?.ranges" [co2Value]="co2Value?.valuePerPortionInKg"></app-co2-range-view>

</div>