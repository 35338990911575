import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { MenuDay } from '../menu-view/menu-view.component';
import { MenuViewService } from '../menu-view/menu-view.service';
import { MenuDayRowInfoDialogComponent, MenuDayRowInfoDialogData } from '../menu-day-row-info-dialog/menu-day-row-info-dialog.component';
import { Co2Range, MenuPublishNutritiveItem } from 'src/data/interfaces';
import { CustomL10nIntlService, FormatType } from 'src/data/custom-locale.service';
import { getWeekDayNumber as getWeekDayNumber } from 'src/date-functions';
import { L10nTranslationService } from 'angular-l10n';
import { TK } from 'src/data/translation-keys';
import { getDateAsString } from 'src/date-functions';
import { Co2ValueUI, getCo2ValueForUi } from '../tools/co2-tools';

@Component({
  selector: 'app-menu-day',
  templateUrl: './menu-day.component.html',
  styleUrls: ['./menu-day.component.scss']
})
export class MenuDayComponent implements OnInit, OnDestroy {

  _menuDay!: MenuDay;
  @Input() set menuDay(menuDay: MenuDay) {
    this._menuDay = menuDay;
    this.initializeData();
  }
  public processInProgress: boolean = false;

  _language!: string;
  @Input() set language(language: string) {
    this._language = language;
    this.initializeData();
  }

  _service!: MenuViewService;
  @Input() set service(service: MenuViewService) {
    this._service = service;
    this.subscriptions.push(
      this._service.getData().subscribe((menuDay: MenuDay) => {
        if (menuDay?.date === this._menuDay.date) {
          this._menuDay = menuDay;
          this.initializeData();
          this.processInProgress = false;
        }
      })
    );
  }

  _noDataRequest!: boolean;
  @Input() set noDataRequest(noDataRequest: boolean) {
    this._noDataRequest = noDataRequest;
    this.initializeData();
  }

  public dateFormatted: string;
  public uiMealOptions: UiMealOption[];
  public expandedDefault: boolean = false;
  public legendRanges: Co2Range[];

  private subscriptions: Subscription[] = [];

  public tk = {
    carbon_footprint: '',
    very_low: '',
    low: '',
    medium: '',
    high: '',
    very_high: '',
    co2_unit_short: '',
    no_information: '',
    info: ''
  }

  constructor(
    private dialog: MatDialog,
    private customLocaleService: CustomL10nIntlService,
    public ts: L10nTranslationService
  ) { }

  private initializeData(): void {

    if (this._language == null || this._menuDay == null || this._noDataRequest == null) {
      return;
    }

    this.tk.no_information = this.ts.translate(TK.no_information);
    this.tk.info = this.ts.translate(TK.info);
    this.tk.carbon_footprint = this.ts.translate(TK.carbon_footprint);
    this.tk.very_low = this.ts.translate(TK.very_low);
    this.tk.low = this.ts.translate(TK.low);
    this.tk.medium = this.ts.translate(TK.medium);
    this.tk.high = this.ts.translate(TK.high);
    this.tk.very_high = this.ts.translate(TK.very_high);
    this.tk.co2_unit_short = this.ts.translate(TK.co2_unit_short);

    this.dateFormatted = null;
    this.dateFormatted = this._menuDay.date != null ?
      this.customLocaleService.format(this._menuDay.date, FormatType.formatDate) :
      '';

    if (this._menuDay.date != null) {
      const weekDayNameKey: string = `week_day_name_${getWeekDayNumber(new Date(this._menuDay.date))}`;

      const weekDayName: string = this.ts.translate(weekDayNameKey);
      this.dateFormatted = `${weekDayName} ${this.dateFormatted}`;
    }

    this.expandedDefault = this.isCurrentDate() && this._noDataRequest === false;

    this.uiMealOptions = [];


    (this._menuDay.menuPublishDate?.mealOptions ?? []).forEach(uiMealOption => {

      this.uiMealOptions.push({
        name: uiMealOption.names.find(name => name.language === this._language)?.name,
        rows: uiMealOption.rows.map(row => {
          const dietsToSet: string[] = row.diets.find(row => row.language === this._language)?.diets;
          return {
            name: row.names.find(name => name.language === this._language)?.name,
            allergens: row.allergens.find(row => row.language === this._language)?.allergens,
            diets: dietsToSet,
            showDiets: dietsToSet?.length > 0,
            dietShort: row.diets.find(row => row.language === this._language)?.dietShorts,
            co2Value: getCo2ValueForUi(row.co2Value, this.customLocaleService),
            ingredients: row.ingredients.find(row => row.language === this._language)?.ingredients,
            publicInfo: row.publicInfos.find(info => info.language === this._language)?.publicInfo,
            nutritive: row.nutritiveItem,
          }
        })
      })

    });

    this.legendRanges = this.getLegendData();
  }

  public openRowInfo(row: UiRow): void {
    const data: MenuDayRowInfoDialogData = {
      row,
      language: this._language
    }

    const dialogRef = this.dialog.open(MenuDayRowInfoDialogComponent, {
      disableClose: false,
      maxWidth: '800px',
      minWidth: '400px',
      minHeight: '400px',
      autoFocus: false,
      panelClass: 'form-dialog-container',
      data: data
    });

    const subscription: Subscription = dialogRef.afterClosed().subscribe(url => {
      if (!!subscription) subscription.unsubscribe();
    });
    this.subscriptions.push(subscription);
  }

  public opened(): void {
    if (this._menuDay.menuPublishDate == null && this._menuDay.noData !== true && this._noDataRequest === false) {
      this.processInProgress = true;
      // Hax to make sure that all menuDayCoponents have been initialized before they are sent the data.
      // If data is in cache and day component is opend in html page load and dynamic day component is before print day 
      // component then dynamic day component requests and receives the data before the print component is initialized. When 
      // page is printed the print component has no data (of the current day) and day is not shown in printout.
      setTimeout(() => {
        this._service.requestData(this._menuDay.date);
      });
    }
  }

  public closed(): void {
  }

  public isCurrentDate(): boolean {
    const now: Date = new Date();
    const currentDate: string = getDateAsString(now);
    return this._menuDay.date === currentDate;
  }

  public getDateStyle(): string {
    return this.isCurrentDate() ? 'container__menu-day-date--current' : 'container__menu-day-date';
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  getLegendData(): Co2Range[] {
    return this._menuDay?.menuPublishDate?.mealOptions?.flatMap(mealOption => mealOption.rows)?.find(row => row.co2Value != null)?.co2Value?.ranges;
  }

}

export interface UiMealOption {
  name: string;
  rows: UiRow[];
}

export interface UiRow {
  name: string;
  allergens: string[];
  ingredients: string;
  diets: string[];
  showDiets: boolean;
  dietShort: string[];
  co2Value: Co2ValueUI;
  nutritive: MenuPublishNutritiveItem;
  publicInfo: string;
}